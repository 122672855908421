// Login Constants
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

// Register Constants
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

// Panélistes Register Constants
export const PANELISTES_REGISTER_REQUEST = "PANELISTES_REGISTER_REQUEST";
export const PANELISTES_REGISTER_SUCCESS = "PANELISTES_REGISTER_SUCCESS";
export const PANELISTES_REGISTER_FAIL = "PANELISTES_REGISTER_FAIL";

// Users Details Constants
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_UPDATE_PROFILE_RESET";

// Users Update_profil Constants
export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

// Users List Constants
export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

// Delete Users Constants
export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

// Users List Constants
export const USER_UPDATE_ADMIN_REQUEST = "USER_UPDATE_ADMIN_REQUEST";
export const USER_UPDATE_ADMIN_SUCCESS = "USER_UPDATE_ADMIN_SUCCESS";
export const USER_UPDATE_ADMIN_FAIL = "USER_UPDATE_ADMIN_FAIL";
export const USER_UPDATE_ADMIN_RESET = "USER_UPDATE_ADMIN_RESET";

// Survey Display (on Profil Page)
export const USER_SURVEY_DISPLAY_REQUEST = "USER_SURVEY_DISPLAY_REQUEST";
export const USER_SURVEY_DISPLAY_SUCCESS = "USER_SURVEY_DISPLAY_SUCCESS";
export const USER_SURVEY_DISPLAY_FAIL = "USER_SURVEY_DISPLAY_FAIL";

// Survey Participants (in Django Database)
export const USER_SURVEY_DONE_REQUEST = "USER_SURVEY_DONE_REQUEST";
export const USER_SURVEY_DONE_SUCCESS = "USER_SURVEY_DONE_SUCCESS";
export const USER_SURVEY_DONE_FAIL = "USER_SURVEY_DONE_FAIL";

// Cagnotte Update
export const USER_CAGNOTTE_UPDATE_REQUEST = "USER_CAGNOTTE_UPDATE_REQUEST";
export const USER_CAGNOTTE_UPDATE_SUCCESS = "USER_CAGNOTTE_UPDATE_SUCCESS";
export const USER_CAGNOTTE_UPDATE_FAIL = "USER_CAGNOTTE_UPDATE_FAIL";

// Enquête en cours
export const ONGOING_SURVEY_REQUEST = "ONGOING_SURVEY_REQUEST";
export const ONGOING_SURVEY_SUCCESS = "ONGOING_SURVEY_SUCCESS";
export const ONGOING_SURVEY_FAIL = "ONGOING_SURVEY_FAIL";

// RESET PASSWORD
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

// NEW PASSWORD CONFIRM
export const CONFIRM_PASSWORD_REQUEST = "CONFIRM_PASSWORD_REQUEST";
export const CONFIRM_PASSWORD_SUCCESS = "CONFIRM_PASSWORD_SUCCESS";
export const CONFIRM_PASSWORD_FAIL = "CONFIRM_PASSWORD_FAIL";
