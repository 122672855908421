import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

function Quotas() {
  return (
    <Container className="py-5">
      <h2 className="text-center py-5" style={{ color: "#FF8C00" }}>
        Sélection aléatoire des quotas
      </h2>
      <p className="text-center">
        Indiquez le nombre de panélistes souhaité dans chacunes des
        sous-catégories suivantes, selon les quotas de références.
      </p>
      {/* SEXE */}
      <Row>
        {" "}
        <Col></Col>
        <Col className="text-center" xs={8} md={4}>
          <h3 style={{ color: "green" }}>Sexe</h3>{" "}
          <Form.Label column sm="2">
            Femme
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Homme
          </Form.Label>
          <Form.Control type="number" placeholder="" />
        </Col>
        <Col></Col>
      </Row>
      <br />
      {/* TRANCHE D'AGE */}
      <Row>
        <Col></Col>
        <Col className="text-center" xs={8} md={4}>
          {" "}
          <h3 style={{ color: "green" }}>Tranche d'âge</h3>{" "}
          <Form.Label column sm="2">
            15-29 ans
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            30-44 ans
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            45-60 ans
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Plus de 60 ans
          </Form.Label>
          <Form.Control type="number" placeholder="" />
        </Col>

        <Col></Col>
      </Row>
      <br />
      {/* CSP */}
      <Row>
        {" "}
        <Col></Col>
        <Col className="text-center" xs={8} md={6}>
          <h3 style={{ color: "green" }}>CSP</h3>{" "}
          <Form.Label column sm="2">
            Agriculteurs exploitants
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Artisans, commerçants et chefs d'entreprise
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Cadres et professions intellectuelles supérieures
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Professions intermédiaires
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Employés
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Ouvriers
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Retraités
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Sans activités
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Étudiants
          </Form.Label>
          <Form.Control type="number" placeholder="" />
        </Col>
        <Col></Col>
      </Row>
      <br />
      {/* AGGLOMERATION */}
      <Row>
        <Col></Col>
        <Col className="text-center" xs={8} md={4}>
          <h3 style={{ color: "green" }}>Agglomération</h3>{" "}
          <Form.Label column sm="2">
            Nord
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Centre
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <Form.Label column sm="2">
            Sud
          </Form.Label>
          <Form.Control type="number" placeholder="" />
          <br />
          {""}
          {/* Bouton de génération du fichier */}
          <Button variant="success">Générer la sélection</Button>
        </Col>

        <Col></Col>
      </Row>
    </Container>
  );
}

export default Quotas;
