import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";

// ACTION SURVEY (REDUX)
import {
  OngoingSurvey,
  updateSurveyParticipants,
} from "../../redux/actions/userActions";

// ECRAN INITIAL + ECRAN FINAL
import EcranFinal from "./EcranFinal";
import EcranInitial from "./EcranInitial";

// STYLE MESSAGE D'ERREUR
import Message from "../../components/Message";

function Enquete({ onSetStep, history }) {
  // DONNÉES UTILISATEUR
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // AFFICHER LE MODAL
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Variables
  const [sexe, setSexe] = useState("");
  const [age, setAge] = useState("");
  const [agglomeration, setAgglomeration] = useState("");
  const [csp, setCsp] = useState("");
  const [question_a, setQuestion_a] = useState("");
  const [question_b, setQuestion_b] = useState("");
  const [question_c, setQuestion_c] = useState("");
  const [question_d, setQuestion_d] = useState("");

  // Message d'erreur
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    console.log(sexe);
    console.log(age);
    console.log(agglomeration);
    console.log(csp);
    console.log(question_a);
    console.log(question_b);
    console.log(question_c);
    console.log(question_d);
  }, [
    sexe,
    age,
    agglomeration,
    csp,
    question_a,
    question_b,
    question_c,
    question_d,
  ]);

  // Dispatch - REDUX
  const dispatch = useDispatch();

  // Lancement du questionnaire
  const [step, setStep] = useState(1);

  const quizStartHandler = () => {
    setStep(2);
  };

  // Fonction d'envoi des données vers la BDD + message final
  const validation = () => {
    // Vérification des enquêtes
    if (
      userInfo &&
      sexe.length > 0 &&
      age.length > 0 &&
      agglomeration.length > 0 &&
      csp.length > 0 &&
      question_a.length > 0 &&
      question_b.length > 0 &&
      question_c.length > 0 &&
      question_d.length > 0
    ) {
      // Envoi des données vers la BDD DJANGO
      dispatch(
        OngoingSurvey(
          sexe,
          age,
          agglomeration,
          csp,
          question_a,
          question_b,
          question_c,
          question_d
        )
      );

      // Ajout de la participation à l'enquête au compte utilisateur
      dispatch(updateSurveyParticipants({ user: userInfo.id }));

      // Message d'envoie réussi
      console.log("Envoi réussi vers la BDD");

      // Affichage du message de fin d'enquête
      setStep(3);
    } else {
      setErrorMessage(
        "⚠️ Certaines questions n'ont pas reçu de réponses. Complétez-les, puis valider le questionnaire de nouveau."
      );
    }

    // Condition : une seule participation par personne
    // Les chiffres (4 et 5) = l'ID de l'enquête dans la BDD / 0 est un chiffre !
    const participation = userInfo.enquetes[4].id;
    if (participation === 5) {
      console.log("User has already participed");
    } else {
      console.log("New user participation");
    }
  };

  return (
    <Container className="py-5">
      {/* QUESTIONNAIRE */}

      {step === 1 && <EcranInitial onQuizStart={quizStartHandler} />}
      {step === 2 && (
        <Row>
          <Col></Col>
          <Col md={12} xs={12}>
            {/* <Button variant="primary" onClick={handleShow}>
              Démarrer l'enquête
            </Button> */}
            {/* <Modal show={show} onHide={handleClose} size="md" backdrop="static">
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "green" }}>
                  Exemple de questionnaire
                </Modal.Title>
              </Modal.Header>
              <Modal.Body> */}
            {/* ESPACE QUESTIONNAIRE */}
            <Form>
              <Row>
                <Col>
                  {/* SEXE */}
                  <h6 className="question-title">Sexe</h6>
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        required
                        inline
                        label="Femme"
                        name="sexe"
                        type={type}
                        id={`inline-${type}-sexe1`}
                        value="Femme"
                        onChange={(event) => setSexe(event.target.value)}
                      />
                      <Form.Check
                        required
                        inline
                        label="Homme"
                        name="sexe"
                        type={type}
                        id={`inline-${type}-sexe2`}
                        value="Homme"
                        onChange={(event) => setSexe(event.target.value)}
                      />
                    </div>
                  ))}
                  <br className="py-3" />
                  {/* TRANCHE D'AGE */}
                  <h6 className="question-title">Tranche d'âge</h6>
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        required
                        inline
                        label="18-25 ans"
                        name="age"
                        type={type}
                        id={`inline-${type}-age1`}
                        value="18-25 ans"
                        onChange={(event) => setAge(event.target.value)}
                      />
                      <Form.Check
                        required
                        inline
                        label="26-35 ans"
                        name="age"
                        type={type}
                        id={`inline-${type}-age2`}
                        value="26-35 ans"
                        onChange={(event) => setAge(event.target.value)}
                      />
                      <Form.Check
                        required
                        inline
                        label="36-45 ans"
                        name="age"
                        type={type}
                        id={`inline-${type}-age3`}
                        value="36-45 ans"
                        onChange={(event) => setAge(event.target.value)}
                      />
                      <Form.Check
                        required
                        inline
                        label="46-55 ans"
                        name="age"
                        type={type}
                        id={`inline-${type}-age4`}
                        value="46-55 ans"
                        onChange={(event) => setAge(event.target.value)}
                      />
                      <Form.Check
                        required
                        inline
                        label="56-64 ans"
                        name="age"
                        type={type}
                        id={`inline-${type}-age5`}
                        value="56-64 ans"
                        onChange={(event) => setAge(event.target.value)}
                      />
                      <Form.Check
                        required
                        inline
                        label="65 ans et plus"
                        name="age"
                        type={type}
                        id={`inline-${type}-age5`}
                        value="65 ans et plus"
                        onChange={(event) => setAge(event.target.value)}
                      />
                    </div>
                  ))}{" "}
                  <br />
                  <Form.Group as={Col} controlId="CSP">
                    <Form.Label
                      style={{ color: "#337ff1", fontWeight: "bold" }}
                    >
                      <h6 className="question-title">
                        Catégorie Socio-professionnelle
                      </h6>
                    </Form.Label>
                    <Form.Select
                      required
                      defaultValue="Sélectionnez..."
                      type="CSP"
                      value={csp}
                      onChange={(event) => setCsp(event.target.value)}
                    >
                      <option hidden value="">
                        Sélectionnez
                      </option>
                      <option>Agriculteurs exploitants</option>
                      <option>
                        Artisans, commerçants et chefs d'entreprise
                      </option>
                      <option>
                        Cadres et professions intellectuelles supérieures
                      </option>
                      <option>Professions intermédiaires</option>
                      <option>Employés</option>
                      <option>Ouvriers</option>
                      <option>Retraités</option>
                      <option>Sans activités</option>
                      <option>Étudiants</option>
                    </Form.Select>
                  </Form.Group>
                  <Col></Col>
                </Col>
              </Row>
              <br />
              <Row className="mb-3">
                <Form.Group as={Col} controlId="agglomeration">
                  <Form.Label style={{ color: "#337ff1", fontWeight: "bold" }}>
                    <h6 className="question-title">
                      Agglomération de résidence
                    </h6>
                  </Form.Label>
                  <Form.Select
                    required
                    defaultValue="Sélectionnez..."
                    type="agglomeration"
                    value={agglomeration}
                    onChange={(event) => setAgglomeration(event.target.value)}
                  >
                    <option hidden value="">
                      Sélectionnez
                    </option>
                    <option>Nord de la Martinique</option>
                    <option>Centre de la Martinique</option>
                    <option>Sud de la Martinique</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row>
                {" "}
                {/* QUESTION A */}
                <h6 className="question-title">Question A</h6>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      required
                      inline
                      label="Plutôt favorable"
                      name="Question A"
                      type={type}
                      id={`inline-${type}-QA1`}
                      value="Plutôt favorable"
                      onChange={(event) => setQuestion_a(event.target.value)}
                    />
                    <br />
                    <Form.Check
                      required
                      inline
                      label="Favorable"
                      name="Question A"
                      type={type}
                      id={`inline-${type}-QA2`}
                      value="Favorable"
                      onChange={(event) => setQuestion_a(event.target.value)}
                    />
                    <br />
                    <Form.Check
                      required
                      inline
                      label="Plutôt défavorable"
                      name="Question A"
                      type={type}
                      id={`inline-${type}-QA3`}
                      value="Plutôt défavorable"
                      onChange={(event) => setQuestion_a(event.target.value)}
                    />
                    <br />
                    <Form.Check
                      required
                      inline
                      label="Défavorable"
                      name="Question A"
                      type={type}
                      id={`inline-${type}-QA4`}
                      value="Défavorable"
                      onChange={(event) => setQuestion_a(event.target.value)}
                    />
                  </div>
                ))}{" "}
                <Col></Col>
              </Row>
              <br></br>
              <Row>
                {" "}
                {/* QUESTION B */}
                <h6 className="question-title">Question B</h6>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      required
                      inline
                      label="Oui"
                      name="Question B"
                      type={type}
                      id={`inline-${type}-QB1`}
                      value="Oui"
                      onChange={(event) => setQuestion_b(event.target.value)}
                    />
                    <br />
                    <Form.Check
                      required
                      inline
                      label="Non"
                      name="Question B"
                      type={type}
                      id={`inline-${type}-QB2`}
                      value="Non"
                      onChange={(event) => setQuestion_b(event.target.value)}
                    />
                  </div>
                ))}{" "}
              </Row>
              <br /> {/* QUESTION C */}
              <h6 className="question-title">Question C</h6>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    required
                    inline
                    label="Plutôt convaincu(e)"
                    name="Question C"
                    type={type}
                    id={`inline-${type}-QC1`}
                    value="Plutôt convaincu(e)"
                    onChange={(event) => setQuestion_c(event.target.value)}
                  />
                  <br />
                  <Form.Check
                    required
                    inline
                    label="Convaincu(e)"
                    name="Question C"
                    type={type}
                    id={`inline-${type}-QC2`}
                    value="Convaincu(e)"
                    onChange={(event) => setQuestion_c(event.target.value)}
                  />
                  <br />
                  <Form.Check
                    required
                    inline
                    label="Plutôt pas convaincu(e)"
                    name="Question C"
                    type={type}
                    id={`inline-${type}-QC3`}
                    value="Plutôt pas convaincu(e)"
                    onChange={(event) => setQuestion_c(event.target.value)}
                  />
                  <br />
                  <Form.Check
                    required
                    inline
                    label="Pas convaincu(e)"
                    name="Question C"
                    type={type}
                    id={`inline-${type}-QC4`}
                    value="Pas convaincu(e)"
                    onChange={(event) => setQuestion_c(event.target.value)}
                  />
                </div>
              ))}{" "}
              <br></br>
              <Row>
                <br /> {/* QUESTION D */}
                <h6 className="question-title">Question D</h6>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      required
                      inline
                      label="Plutôt favorable"
                      name="Question D"
                      type={type}
                      id={`inline-${type}-QD1`}
                      value="Plutôt favorable"
                      onChange={(event) => setQuestion_d(event.target.value)}
                    />
                    <br />
                    <Form.Check
                      required
                      inline
                      label="Favorable"
                      name="Question D"
                      type={type}
                      id={`inline-${type}-QD2`}
                      value="Favorable"
                      onChange={(event) => setQuestion_d(event.target.value)}
                    />
                    <br />
                    <Form.Check
                      required
                      inline
                      label="Plutôt défavorable"
                      name="Question D"
                      type={type}
                      id={`inline-${type}-QD3`}
                      value="Plutôt défavorable"
                      onChange={(event) => setQuestion_d(event.target.value)}
                    />
                    <br />
                    <Form.Check
                      required
                      inline
                      label="Défavorable"
                      name="Question D"
                      type={type}
                      id={`inline-${type}-QD4`}
                      value="Défavorable"
                      onChange={(event) => setQuestion_d(event.target.value)}
                    />
                  </div>
                ))}{" "}
                <br></br>
              </Row>
              <Col></Col>
              <br />
              {errorMessage && (
                <Message variant="danger" className="text-center">
                  {" "}
                  {errorMessage}{" "}
                </Message>
              )}
              <br />
              <Button variant="success" onClick={validation}>
                Valider le questionnaire
              </Button>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      )}
      {step === 3 && <EcranFinal />}
    </Container>
  );
}

export default Enquete;
