import React, { useEffect, useState } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";

function Charte() {
  useEffect(() => {
    // Back to the top when loading page
    window.scrollTo(0, 0);
  });

  return (
    <main>
      <Container className="py-5">
        <Row className="py-5">
          <h2 className="py-3 text-center">Préambule</h2>

          <p className="text-center">
            Les conditions exposées dans la présente charte ont vocation à
            s’appliquer entre la société AGENCE MARKETIC – concepteur et
            propriétaire de <strong>PANEL ANTILLES</strong> – SARL au capital de
            1 000€, immatriculée au RCS de Fort-de-France sous le n°
            878.839.422, dont le siège social se situe à Reculé, Rivière Romanet
            97230 Sainte-Marie, représentée par son Dirigeant en exercice au-dit
            siège, et la personne qui candidate à s’inscrire sur{" "}
            <strong>PANEL ANTILLES</strong>, ci-après dénommée le «{" "}
            <strong>PANELISTE</strong> ». Les deux parties s’engagent à les
            respecter mutuellement. A défaut, l’autre partie pourra se délier de
            ses obligations.
          </p>

          {/* FREE COLUMN */}
          <Col className="py-5 center-element" style={{ color: "black" }}></Col>

          {/* MAIN COLUMN */}
          <Col xs={12} md={10} className="py-5 center-element" xs={10} md={8}>
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                {/* ARTICLE 1 */}
                <Accordion.Header>
                  <p style={{ color: "orange" }}>
                    Article 1 – Objet de la charte
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <strong>PANEL ANTILLES</strong> a pour activité de réaliser
                  des études de marché qualitatives et quantitatives, par le
                  biais de sondages et questionnaires d’une population ou d’un
                  groupe identifié de particuliers consommateurs ou de
                  professionnels.
                  <br></br>
                  <br></br>
                  Pour cela, <strong>PANEL ANTILLES</strong> contacte les
                  particuliers et professionnels qui ont manifesté l’intérêt de
                  participer à des études/enquêtes en s’inscrivant sur le site
                  www.panel-antilles.fr.
                  <br></br>
                  <br></br>
                  Il est expressément entendu que la participation du{" "}
                  <strong>PANELISTE</strong> {"  "} est volontaire, libre et
                  indépendante, ce qui exclut tout lien de subordination entre
                  le <strong>PANELISTE</strong> et{" "}
                  <strong>PANEL ANTILLES</strong>.
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE 2 */}
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <p style={{ color: "#2fa4e7" }}>Article 2 – Inscription</p>
                </Accordion.Header>
                <Accordion.Body>
                  2.1. Procédure d’inscription
                  <br></br>
                  <br></br>
                  L’inscription d’un nouveau <strong>PANELISTE</strong> dans les
                  fichiers de PANEL ANTILLES est subordonnée à :<br></br>
                  <ul>
                    <li>
                      une inscription volontaire et parfaitement consentie,
                    </li>
                    <li>
                      la communication par le candidat{" "}
                      <strong>PANELISTE</strong> d’un ensemble de données
                      énumérées sur le formulaire d’inscription accessible
                      depuis le site www.panel-antilles.fr,
                    </li>
                    <li>
                      l’acceptation des présentes conditions par le candidat
                      {"  "} <strong>PANELISTE</strong>,
                    </li>
                    <li>
                      l’envoi au <strong>PANELISTE</strong> par voie d’email, de
                      l’ouverture de son compte, de l’attribution d’un
                      identifiant et d’un mot de passe valant parfaite et
                      expresse acceptation de la présente charte,
                    </li>
                    <li>
                      <strong>PANEL ANTILLES</strong> se réserve la faculté
                      d’accepter ou de refuser tout nouveau{" "}
                      <strong>PANELISTE</strong> sans avoir à lui justifier les
                      raisons de son acceptation ou de son refus.
                    </li>
                  </ul>
                  <br></br>
                  <br></br>
                  2.2. Effets de l’inscription
                  <br></br>
                  <br></br>
                  Le <strong>PANELISTE</strong> une fois son inscription prise
                  en compte, accède à l’ensemble des services proposés sur le
                  site de PANEL ANTILLES et notamment à l’opportunité de pouvoir
                  être sollicité pour une étude et de répondre à des
                  questionnaires par emailing ou à des contacts téléphoniques
                  directs en vue de candidater à une nouvelle étude.
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE 3 */}
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <p style={{ color: "orange" }}>
                    Article 3 – Transmission des données personnelles par le
                    PANELISTE{" "}
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  3.1. Contenu des données
                  <br></br>
                  <br></br>
                  Les données personnelles transmises par le{" "}
                  <strong>PANELISTE</strong>
                  renseignent <strong>PANEL ANTILLES</strong> sur son état
                  civil, sa situation matrimoniale, familiale, sociale,
                  patrimoniale, fiscales, sur ses habitudes de consommation…
                  Elles restent strictement confidentielles.
                  <br></br>
                  <strong>PANEL ANTILLES</strong> se réserve le droit de
                  demander au <strong>PANELISTE</strong> de lui fournir toutes
                  autres informations complémentaires ou justificatifs
                  nécessaires à la parfaite exécution et traitement de l’étude
                  qui lui est confiée.
                  <br></br>
                  <br></br>
                  3.2. Modification et actualisation des données
                  <br></br>
                  <br></br>
                  Le <strong>PANELISTE</strong> s’engage à actualiser les
                  données le concernant et figurant sous son « profil » sur le
                  site de <strong>PANEL ANTILLES</strong> {"  "}
                  dès lors qu’il y a modification de sa situation personnelle ou
                  professionnelle, changement de ses coordonnées contact, de
                  manière tout autant à faciliter le travail de sélection
                  effectué par <strong>PANEL ANTILLES</strong> qu’à maintenir
                  ses chances d’être sollicité pour de nouvelles études.
                  <br></br>A ce titre, le <strong>PANELISTE</strong>, afin de
                  permettre le maintien à jour de sa base de données, autorise{" "}
                  <strong>PANEL ANTILLES</strong> {"  "}à le solliciter sur la
                  mise à jour de son profil.
                  <br></br>
                  <br></br>
                  3.3. Engagement de sincérité
                  <br></br>
                  <br></br>
                  Le <strong>PANELISTE</strong> garantit formellement{" "}
                  <strong>PANEL ANTILLES</strong> que les données qu’il
                  communique sont sincères et exactes. PANEL ANTILLES se réserve
                  le droit d’exclure tout <strong>PANELISTE</strong> qui aurait
                  fourni des données fausses ou volontairement trompeuses au
                  moment de son inscription, ou qui aurait procédé à des
                  inscriptions multiples ou tenté d’abuser par tous moyens le
                  professionnalisme de
                  <strong>PANEL ANTILLES</strong>.
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE  4*/}
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <p style={{ color: "#2fa4e7" }}>
                    Article 4 – Engagements de PANEL ANTILLES
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  4.1. Protection des données personnelles
                  <br></br>
                  <br></br>
                  Qu’est-ce qu’une donnée personnelle ?<br></br>
                  <br></br>
                  Le RGPD (Règlement Général sur la Protection des Données)
                  étend la définition des données personnelles. Ce qui constitue
                  les « données personnelles » est beaucoup plus large et couvre
                  spécifiquement les « identifiants en ligne ». Les données
                  personnelles sont toutes les informations associées à une
                  personne. Il s’agit principalement des coordonnées mais
                  également des informations pouvant être utilisées directement
                  ou indirectement pour identifier une personne.
                  <br></br>
                  <br></br>
                  Nous conseillons à chacun de nos
                  <strong>PANELISTE</strong>S de bien comprendre ce qui est
                  considéré comme des données personnelles :<br></br>
                  <br></br>
                  Informations identifiables comme :<br></br>
                  <br></br>
                  <ul>
                    <li>Nom </li>
                    <li>Adresse</li> <li>Adresse IP </li>
                    <li>Photo</li>
                    <li>Enregistrement audio / vidéo</li>{" "}
                    <li>Adresse électronique</li> <li>Coordonnées bancaires</li>{" "}
                    <li>
                      Comportement en ligne : cookies, posts sur les réseaux
                      sociaux
                    </li>
                    <li>Adresse IP ordinateur</li>{" "}
                    <li>Identifiant téléphone portable </li>
                  </ul>
                  <br></br>
                  <ul>
                    Informations sur le profil/sensibles/catégorie particulière
                    comme :<br></br>
                    <br></br>
                    <li>Informations sur le foyer</li>{" "}
                    <li>Comportement et attitudes Informations médicales</li>{" "}
                    <li>Maladies</li> <li>Appartenance ethnique</li>{" "}
                    <li>Religion</li> <li>Adhésion à un syndicat</li>{" "}
                    <li>Orientation sexuelle</li> <li>Opinions politiques</li>{" "}
                    <li>Données biométriques</li> <li>Données génériques</li>{" "}
                  </ul>
                  <br></br>
                  <br></br>
                  Le <strong>PANELISTE</strong> est informé que les bases de
                  données de
                  <strong>PANEL ANTILLES</strong> respectent la réglementation
                  en vigueur renforcée par le Règlement (UE) n° 2016/679 dit «
                  Règlement Général sur la Protection des Données » (ensemble le
                  « Droit Applicable à la Protection des Données Personnelles
                  »).
                  <br></br>
                  <br></br>
                  PANEL ANTILLES est inscrit à la Commission Nationale
                  Informatique et Liberté (CNIL) sous le numéro 1311734 et à ce
                  titre adhère aux clauses de confidentialité des données
                  nominatives de ses membres
                  <strong>PANELISTES</strong>.<br></br>
                  <br></br>
                  En vertu des dispositions de la loi « Informatique et Libertés
                  » n° 78-17 du 6 janvier 1978 et du Règlement (UE) n° 2016/679
                  dit « Règlement Général sur la Protection des Données »
                  (ensemble le « Droit Applicable à la Protection des Données
                  Personnelles »), tout <strong>PANELISTE</strong> dispose d’un
                  droit d’opposition (article 26), d’accès (articles 34 à 38),
                  de rectification (article 36), de limitation, d’effacement et
                  de portabilité des Données Personnelles le concernant.
                  <br></br>
                  <br></br>
                  Pour exercer ce droit, le <strong>PANELISTE</strong> pourra
                  s’adresser par voie postale à <strong>PANEL ANTILLES</strong>,
                  Reculé, Rivière Romanet – 97230 Sainte-Marie ou par mail à
                  panel.antilles972@gmail.com.
                  <br></br>
                  <br></br>
                  4.2. Confidentialité
                  <br></br>
                  <br></br>
                  PANEL ANTILLES s’engage à ne pas divulguer à des tiers les
                  informations personnelles et nominatives en sa possession et
                  notamment les coordonnées du <strong>PANELISTE</strong>, sauf
                  accord explicite de ce dernier, à l’exception des informations
                  strictement nécessaires à la phase de recrutement ou au bon
                  déroulement de l’étude. <strong>PANEL ANTILLES</strong>{" "}
                  prendra vis-à-vis de ses collaborateurs les mesures
                  nécessaires pour conserver le secret et la confidentialité de
                  toutes les informations, données et documents personnels
                  communiqués par le <strong>PANELISTE</strong>.<br></br>
                  <br></br>
                  <strong>PANEL ANTILLES</strong> met en œuvre et maintien des
                  mesures appropriées de sécurité de son système d’information
                  afin de protéger la confidentialité des Données Personnelles,
                  conformément aux exigences du Droit Applicable à la Protection
                  des Données. <strong>PANEL ANTILLES</strong> s’engage à ne pas
                  céder, louer ou transmettre les Données Personnelles du{" "}
                  <strong>PANELISTE</strong> à des tiers non autorisés, sauf
                  obligation légale ou judiciaire lui enjoignant de le faire.
                  <br></br>
                  <br></br>
                  En conséquence, <strong>PANEL ANTILLES</strong>
                  s’engage à respecter les obligations suivantes et à les faire
                  respecter par ses tiers :<br></br>
                  <br></br>
                  <ul>
                    {" "}
                    <li>
                      traiter les Données Personnelles dans le cadre strict et
                      nécessaire des prestations convenues entre les parties au
                      titre du contrat et n’agir que sur la base des
                      instructions documentées du client ;
                    </li>{" "}
                    <li>
                      assurer la confidentialité des Données Personnelles et
                      veiller à ce que chaque personne autorisée à traiter les
                      dites données s’engage à respecter la confidentialité ou
                      soit soumise à une obligation appropriée de
                      confidentialité (article 9) ;
                    </li>{" "}
                    <li>
                      assurer la sécurité et l’intégrité des Données
                      Personnelles. A ce titre, <strong>PANEL ANTILLES</strong>{" "}
                      met en œuvre et maintient des mesures appropriées de
                      sécurité de son système d’information, conformément aux
                      exigences du Droit Applicable à la Protection des Données.
                      Ces mesures visent à (i) protéger les Données Personnelles
                      contre leur destruction, perte, altération, divulgation à
                      des tiers non autorisés, (ii) assurer le rétablissement de
                      la disponibilité des Données Personnelles et l’accès à
                      celles-ci dans des délais appropriés en cas d’incident
                      physique ou technique ;
                    </li>{" "}
                    <li>
                      ne pas utiliser les Données Personnelles à d’autres fins
                      que celles prévues au contrat et strictement liées à la
                      réalisation des prestations convenues entre les parties,
                      et ne pas les conserver au-delà de la durée du contrat ou
                      toute autre durée spécifiée par le client. En tout état de
                      cause, <strong>PANEL ANTILLES</strong> s’engage à
                      supprimer et détruire toute copie ou restituer au client
                      toutes Données Personnelles au terme du contrat, à
                      l’exception d’une copie conservée par{" "}
                      <strong>PANEL ANTILLES</strong> à des fins de preuve de la
                      bonne exécution de ses obligations contractuelles ;
                    </li>{" "}
                    <li>
                      ne pas concéder, louer, céder ou autrement communiquer à
                      une autre personne, tout ou partie des Données
                      Personnelles ;
                    </li>{" "}
                    <li>
                      <strong>PANEL ANTILLES</strong>
                      garantit que ses employés et ses clients offre des
                      garanties suffisantes quant à la mise en œuvre de mesures
                      techniques et organisationnelles appropriées de manière à
                      ce que le traitement réponde aux exigences du Droit
                      Applicable à la Protection des Données Personnelles, et
                      garantisse la protection des droits des personnes
                      concernées ;
                    </li>{" "}
                    <li>
                      apporter son assistance au <strong>PANELISTE</strong> afin
                      de lui permettre de répondre, dans les délais et selon les
                      conditions prévus par le Droit Applicable à la Protection
                      des Données Personnelles, à toute demande d’exercice d’un
                      droit, requête ou plainte d’une personne concernée ou
                      d’une autorité de protection des données ou tout autre
                      régulateur ; apporter son assistance au
                      <strong>PANELISTE</strong> dans le cadre de la réalisation
                      d’analyses d’impact relative à la vie privée et/ou dans le
                      cadre de formalités qui seraient à accomplir par le{" "}
                      <strong>PANELISTE</strong>. Le <strong>PANELISTE</strong>{" "}
                      reconnait et accepte que la prestation d’assistance qui
                      serait à accomplir dans ce cadre fera l’objet d’une
                      proposition de services séparée de la part de
                      <strong>PANEL ANTILLES</strong> ;
                    </li>{" "}
                    <li>
                      mettre à la disposition du <strong>PANELISTE</strong>,
                      sous condition de respect d’un engagement de
                      confidentialité, toutes les informations nécessaires pour
                      démontrer le respect des obligations prévues au présent
                      article et pour permettre la réalisation d’études ;
                    </li>{" "}
                    <li>
                      à ne pas transférer les Données Personnelles traitées dans
                      le cadre du contrat vers des pays hors de l’espace
                      économique européen qui n’auraient pas été reconnus par la
                      Commission européenne comme assurant un niveau de
                      protection adéquat (i) sans avoir préalablement obtenu
                      l’autorisation expresse et écrite du{" "}
                      <strong>PANELISTE</strong>, (ii) sans analyse du pays vers
                      lequel sera fait le transfert de données afin d’assurer un
                      niveau adéquat de protection des données et (iii) sans la
                      mise en place d’instruments juridiques reconnus comme
                      appropriés par le Droit Applicable à la Protection des
                      Données Personnelles pour encadrer le ou les transfert(s)
                      concerné(s).
                    </li>
                  </ul>
                  <br></br>
                  <br></br>
                  Enfin, PANEL ANTILLES s’engage à alerter immédiatement le{" "}
                  <strong>PANELISTE</strong> en cas de violation des Données
                  Personnelles et à l’assister dans la mise en œuvre de toute
                  action permettant de faire face à cette violation de données,
                  y compris les notifications aux autorités compétentes et aux
                  personnes concernées par les manquements et à apporter tous
                  éléments d’information utiles permettant d’apprécier l’ampleur
                  de la violation de Données Personnelles et d’identifier les
                  moyens pour y remédier.
                  <br></br>
                  <br></br>
                  RESSOURCES : « Le texte du Règlement (UE) 2016/679 du
                  Parlement européen et du Conseil du 27 avril 2016 relatif à la
                  protection des personnes physiques à l’égard du traitement des
                  données à caractère personnel et à la libre circulation de ces
                  données (RGPD) est publié sur le site de la CNIL et le site de
                  l’Union Européenne. » Comprendre le règlement européen sur le
                  site de CNIL
                  <br></br>
                  <br></br>
                  4.3. Code éthique <strong>PANEL ANTILLES</strong> respecte
                  l’ensemble des règles et usages éthiques régissant sa
                  profession ainsi que les règles et usages éthiques relatifs à
                  l’utilisation d’internet.
                  <br></br>
                  <br></br>
                  4.4. Accès au site www.panel-antilles.fr{" "}
                  <strong>PANEL ANTILLES</strong> s’efforce de maintenir
                  l’accessibilité de son site www.panel-antilles.fr.
                  <br></br>
                  <br></br>
                  L’accès peut néanmoins être interrompu ou suspendu pour des
                  raisons de maintenance ou de cas de force majeure.{" "}
                  <strong>PANEL ANTILLES</strong> ne pourra dès lors être tenu
                  pour responsable des dommages indirects, incorporels ou
                  corporels à quelque titre que ce soit et de quelque nature que
                  ce soit consécutif à l’interruption de l’accès au site.{" "}
                  <br></br>
                  <br></br>
                  <strong>PANEL ANTILLES</strong> ne fournissant aucune
                  assistance technique de type « hot line », il revient au{" "}
                  <strong>PANELISTE</strong> d’exécuter correctement les
                  opérations pour renseigner, actualiser ou enrichir ses données
                  personnelles et plus généralement, accéder à son espace
                  personnel.
                  <br></br>
                  <br></br>
                  4.5. Participation du
                  <br></br>
                  <br></br>
                  <strong>PANELISTE</strong> Comment PANEL ANTILLES
                  s’assure-t-il du consentement clair et transparent des
                  participants à l’étude ?<br></br>
                  <br></br>
                  Dans tous les panels de participants européens de
                  <strong>PANEL ANTILLES</strong> ou partenaires, les nouvelles
                  conditions de consentement ont été mises à jour depuis le 25
                  mai 2018.
                  <br></br>
                  <br></br>
                  Les demandes de consentement sont intelligibles et facilement
                  accessibles et mentionnent clairement l’objectif de traitement
                  des données.
                  <br></br>
                  <br></br>
                  Le consentement est clair et distinct des autres sujets et
                  utilise un langage simple. Pour le panel mais également pour
                  des consentements particuliers, l’accord est aussi facile à
                  donner qu’à retirer.
                  <br></br>
                  <br></br>
                  <strong>PANEL ANTILLES</strong> conserve la maîtrise, la
                  gestion et l’organisation des études, sondages ou
                  questionnaires qui lui sont confiés par sa clientèle et se
                  réserve en conséquence de façon discrétionnaire et
                  unilatérale, le choix des participants.{" "}
                  <strong>PANEL ANTILLES</strong> n’a aucune obligation d’envoi
                  ou de soumission d’études, sondages ou questionnaires au{" "}
                  <strong>PANELISTE</strong> inscrit sur son site.
                  <br></br>
                  <br></br>
                  PANEL ANTILLES, pour éviter tout conflit d’intérêt avec son
                  client, informera le <strong>PANELISTE</strong> des
                  éventuelles contre-indications pouvant apparaître dans une
                  étude, un sondage ou un questionnaire et lui communiquera les
                  documents et pièces qu’il jugera nécessaire.
                  <br></br>
                  <br></br>
                  4.6. Responsabilité
                  <br></br>
                  <br></br>
                  <strong>PANEL ANTILLES</strong>
                  veillera à la protection des données personnelles et d’accès
                  au site. Il est expressément convenu qu’
                  <strong>PANEL ANTILLES</strong> n’est tenu qu’à une obligation
                  de moyens notamment pour ce qui s’agit des obligations de
                  protection des données personnelles et d’accès au site.
                  <br></br>
                  <br></br>
                  Compte-tenu de la spécificité de l’utilisation du site
                  internet <strong>PANEL ANTILLES</strong>, le
                  <strong>PANELISTE</strong> peut être confronté à des aléas
                  techniques inhérents à l’utilisation d’internet et des
                  interruptions d’accès qui peuvent en résulter. En conséquence,{" "}
                  <strong>PANEL ANTILLES</strong> ne pourra être tenu pour
                  responsable des éventuels indisponibilités ou ralentissements
                  du site internet.
                  <br></br>
                  <br></br>
                  <strong>PANEL ANTILLES</strong> n’est pas en mesure de
                  garantir la continuité de la disponibilité du site internet
                  au-delà des obligations décrites (Articles 2 à 4), ce que le{" "}
                  <strong>PANELISTE</strong> reconnaît.{" "}
                  <strong>PANEL ANTILLES</strong> n’est en aucun cas responsable
                  des conséquences directes ou indirectes de tout dommage qui
                  affecterait le <strong>PANELISTE</strong> par suite :<br></br>
                  <br></br>
                  <ul>
                    <li>
                      de l’introduction d’un virus informatique ayant un effet
                      sur son bon fonctionnement ;
                    </li>{" "}
                    <li>
                      des modifications apportées par un tiers non mandaté par{" "}
                      <strong>PANEL ANTILLES</strong> ;
                    </li>{" "}
                    <li>
                      d’intrusions illégales ou non autorisées de tout tiers sur
                      le site internet ;
                    </li>{" "}
                    <li>d’un encombrement temporaire de la bande passante ;</li>{" "}
                    <li>
                      en règle générale de tout évènement échappant au contrôle
                      de <strong>PANEL ANTILLES</strong>{" "}
                    </li>
                  </ul>
                  .<br></br>
                  <br></br>
                  Enfin, <strong>PANEL ANTILLES</strong> ne pourra être tenu, à
                  l’égard du <strong>PANELISTE</strong>, responsable des
                  dommages directs ou indirects, incorporels ou corporels à
                  quelque titre que ce soit et de quelque nature que ce soit que
                  ce dernier aurait pu subir du fait de sa participation ou
                  absence de participation à une étude, un sondage ou un
                  questionnaire.
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE  5*/}
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <p style={{ color: "orange" }}>
                    Article 5 – Engagements du PANELISTE
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  5.1. Participation à une étude, une enquête ou un
                  questionnaire Lorsqu’un <strong>PANELISTE</strong> accepte de
                  participer à une étude, il s’engage à faire son maximum pour
                  se rendre disponible et pouvoir y participer le moment venu,
                  En cas d’empêchement, il s’engage à avertir{" "}
                  <strong>PANEL ANTILLES</strong> dès qu’il a connaissance de la
                  survenance de cet empêchement, de manière à permettre à{" "}
                  <strong>PANEL ANTILLES</strong> de pourvoir à son remplacement
                  dans les meilleures conditions.
                  <br></br>
                  <br></br>
                  La sollicitation du <strong>PANELISTE</strong> se fera selon
                  les cas, par courrier postal, par courrier électronique ou par
                  téléphone à l’adresse ou aux coordonnées de contact indiquées
                  par le <strong>PANELISTE</strong> lors de son adhésion ou par
                  tout autre moyen. Le <strong>PANELISTE</strong> s’engage à
                  prendre en compte les contre-indications et possibles conflits
                  d’intérêts accompagnant l’étude, sondage ou questionnaire qui
                  sont portés à son attention par{" "}
                  <strong>PANEL ANTILLES</strong> et à consulter les documents
                  et pièces joints accompagnant ces derniers.
                  <br></br>
                  <br></br>
                  Lorsque le <strong>PANELISTE</strong> est sollicité par
                  internet, <strong>PANEL ANTILLES</strong>
                  s’engage à tout mettre en œuvre pour lui faciliter l’accès au
                  questionnaire et à tous autres documents joints. Il est
                  expressément convenu qu’
                  <strong>PANEL ANTILLES</strong> est tenu à ce titre à une
                  obligation de moyens.
                  <br></br>
                  <br></br>
                  Le <strong>PANELISTE</strong>
                  pourra néanmoins à tout moment, sans préavis ou justification,
                  demander à ne plus être sollicité.
                  <br></br>
                  <br></br>
                  5.2. Restitution Le
                  <strong>PANELISTE</strong> s’engage à restituer à{" "}
                  <strong>PANEL ANTILLES</strong> l’ensemble des documents,
                  données ou informations transmises par{" "}
                  <strong>PANEL ANTILLES</strong> et à ne pas les dupliquer et
                  s’interdit d’en faire la communication.
                  <br></br>
                  <br></br>
                  5.3. Honnêteté et loyauté Le <strong>PANELISTE</strong>{" "}
                  s’engage à répondre spontanément aux questions qui lui seront
                  posées en toute honnêteté, loyauté et indépendance.{" "}
                  <strong>PANEL ANTILLES</strong> ne pourra en aucun cas être
                  tenu pour responsable des conséquences d’une divergence dans
                  les propos tenus par le
                  <strong>PANELISTE</strong> entre la phase de recrutement et
                  celle de la réunion.
                  <br></br>
                  <br></br>
                  5.4. Caractère personnel La participation au panel est
                  strictement nominative. Le <strong>PANELISTE</strong> s’engage
                  donc à faire ses meilleurs efforts pour que personne d’autre
                  ne puisse accéder à son adresse mail et répondre aux études,
                  notamment en ne communiquant à personne l’identifiant ou le
                  mot de passe permettant d’accéder à son espace personnel sur
                  le site PANEL ANTILLES.
                  <br></br>
                  <br></br>
                  5.5. Respect de l’ordre public et des bonnes mœurs Le
                  <strong>PANELISTE</strong> s’engage à respecter la
                  réglementation applicable à la diffusion d’informations et de
                  services sur internet, notamment les dispositions visant la
                  protection de l’ordre public et des bonnes mœurs, et ce, de
                  manière à ce qu’à aucun moment la responsabilité de
                  <strong>PANEL ANTILLES</strong> ne puisse être engagée ou
                  recherchée.
                  <br></br>
                  <br></br>
                  D’une manière générale, le
                  <strong>PANELISTE</strong> garantit expressément{" "}
                  <strong>PANEL ANTILLES</strong> contre tout recours de quelque
                  nature qu’il soit contre toute action ou réclamation qui
                  pourrait être engagée à l’encontre de
                  <strong>PANEL ANTILLES</strong> au titre du contenu des
                  informations, des données ou des documents transmis par le{" "}
                  <strong>PANELISTE</strong>.<br></br>
                  <br></br>
                  5.6. Respect des droits d’auteur et des droits d’autrui Le{" "}
                  <strong>PANELISTE</strong> s’engage à fournir des données
                  et/ou documents (image, texte, vidéos, bandes sonores, …)
                  conformes à la réglementation en vigueur et garantit qu’ils
                  sont libres de tous droits. En particulier il garantit qu’ils
                  pourront être utilisés par <strong>PANEL ANTILLES</strong>{" "}
                  sans que cela porte atteinte aux droits patrimoniaux ou
                  extrapatrimoniaux des tiers et notamment à des droits de la
                  personnalité ou à des droits de la propriété intellectuelle ou
                  industrielles des tiers.
                  <br></br>
                  <br></br>
                  Le <strong>PANELISTE</strong> déclare être le seul
                  propriétaire du contenu des données, informations ou documents
                  transmis sur le site de
                  <strong>PANEL ANTILLES</strong> et être par conséquent le seul
                  titulaire des droits (de la personnalité, de la propriété
                  intellectuelle ou industrielle) qui leur sont attachés.
                  <br></br>
                  <br></br>
                  D’une manière générale, le <strong>PANELISTE</strong>
                  garantit expressément <strong>PANEL ANTILLES</strong> contre
                  tout recours de quelque nature qu’il soit, émanant de tout
                  utilisateur ou de tiers et contre toute action ou réclamation
                  qui pourrait être engagée à l’encontre de PANEL ANTILLES au
                  titre du contenu des informations, données ou documents
                  transmis et de leur utilisation faite par PANEL ANTILLES ou
                  par des tiers.
                  <br></br>
                  <br></br>
                  5.7. Confidentialité Le <strong>PANELISTE</strong>
                  s’engage à conserver confidentiels les informations, données
                  et documents de quelque nature qu’ils soient, auquel il aurait
                  pu avoir accès au cours de la réalisation de toute étude pour
                  laquelle il aura été sollicité ou à laquelle il aura
                  participé. Le <strong>PANELISTE</strong> s’engage à ce titre à
                  ne pas les conserver, à ne pas en discuter, à ne pas les
                  diffuser, représenter, reproduire, distribuer, et de façon
                  générale à ne pas mettre à la disposition des tiers lesdits
                  documents, informations ou données.
                  <br></br>
                  <br></br>
                  Le <strong>PANELISTE</strong> prendra vis-à-vis de son
                  entourage ou de toute personne, toutes les mesures nécessaires
                  pour assurer sous sa responsabilité le secret et la
                  confidentialité de toutes les informations et documents visés
                  à l’alinéa ci-dessus.
                  <br></br>
                  <br></br>
                  Le <strong>PANELISTE</strong> reconnaît les droits de
                  propriété intellectuelle de
                  <strong>PANEL ANTILLES</strong> sur les informations, données
                  et documents transmis ou auxquels il aurait pu avoir accès.
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE  6*/}
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <p style={{ color: "#2fa4e7" }}>
                    Article 6 – Indemnisation du PANELISTE
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  La participation du <strong>PANELISTE</strong> est réalisée à
                  titre gratuit et sans contrepartie.{" "}
                  <strong>PANEL ANTILLES</strong> ne pourra en conséquence être
                  tenu au versement d’un quelconque dédommagement.
                  <br></br>
                  <br></br>
                  Toutefois, et en remerciement pour sa participation à une
                  réunion ou un entretien, le client de
                  <strong>PANEL ANTILLES</strong> pourra remettre (soit
                  directement soit à travers le service du dédommagement confié
                  à <strong>PANEL ANTILLES</strong>) au
                  <strong>PANELISTE</strong> un dédommagement qui ne peut en
                  aucun cas être considéré comme une rémunération. D’ailleurs,
                  il n’est ni soumis à charges sociales, ni soumis à imposition
                  puisqu’il ne peut en aucun cas être considéré comme un revenu.
                  <br></br>
                  <br></br>
                  Ce dédommagement lui sera remis à la fin de la réunion sous
                  forme de chèque bancaire, de virement bancaire, de chèque
                  cadeau ou éventuellement directement sous forme de cadeau. De
                  même, il pourra être adressé par courrier postal suite à
                  l’analyse de l’ensemble des résultats d’une étude. En ce cas,
                  <strong>PANEL ANTILLES</strong> s’engage à tenir informé le{" "}
                  <strong>PANELISTE</strong> du délai de réception du
                  dédommagement.
                  <br></br>
                  <br></br>
                  Chaque société cliente de
                  <strong>PANEL ANTILLES</strong> est seule décisionnaire de ses
                  règles de dédommagement. En aucun cas le{" "}
                  <strong>PANELISTE</strong> ne pourra exiger de dédommagement
                  pour ses frais de transport ou pour la durée de son
                  déplacement ou toute autre raison qu’il lui plairait
                  d’invoquer.
                  <br></br>
                  <br></br>
                  Aucun dédommagement ne sera versé dans l’un des cas suivants :
                  <br></br>
                  <br></br>
                  <ul>
                    <li>
                      retard du <strong>PANELISTE</strong> à la réunion.
                      L’horaire annoncé est celui auquel commence la réunion. La
                      réunion débutant à l’heure précise, le{" "}
                      <strong>PANELISTE</strong> est donc invité à se présenter
                      avec quelques minutes d’avance,
                    </li>
                    <li>
                      divergence entre les propos du <strong>PANELISTE</strong>{" "}
                      relevés lors de la phase de recrutement et ceux tenus lors
                      de la réunion plaçant de fait ledit{" "}
                      <strong>PANELISTE</strong> en situation dite « hors quota
                      » ou « hors cible ». <strong>PANEL ANTILLES</strong> ne
                      pourra en aucun cas être tenu pour responsable des
                      conséquences, pour le <strong>PANELISTE</strong>, de ces
                      divergences ou incohérences de propos,
                    </li>
                    <li>
                      usage par le <strong>PANELISTE</strong> de plusieurs
                      identités dans le but de multiplier le nombre de réunions
                      auxquelles il peut participer,
                    </li>
                    <li>
                      annulation de la réunion par la société cliente de PANEL
                      ANTILLES,
                    </li>
                    <li>
                      non présentation des documents qui ont pu lui être exigés
                      (pièce d’identité, carte grise, questionnaire de
                      validation de recrutement, journal de bord, homework, ….).
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE  7*/}
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <p style={{ color: "orange" }}>
                    Article 7 – Propriété intellectuelle
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <strong>PANEL ANTILLES</strong> se réserve tous les droits de
                  propriété intellectuelle relatif à la réalisation de la
                  mission, sans limitation d’étendue ni de territoire, en ce
                  compris les droits d’auteur relatifs à ladite mission, à
                  savoir les droits d’utilisation, de reproduction, de
                  modification, de traduction et d’adaptation et ce pour tout
                  support présent ou à venir quelle que soit la destination du
                  support, sous réserve bien sûr des droits attribués au{" "}
                  <strong>PANELISTE</strong>.<br></br>
                  <br></br>
                  <strong>PANEL ANTILLES</strong> déclare et garantit être
                  propriétaire ou détenteur autorisé de tous les droits de
                  propriété intellectuelle relatifs aux éléments transmis au{" "}
                  <strong>PANELISTE</strong> dans le cadre des présentes et
                  notamment les textes, images fixes ou animées, fichiers,
                  informations, logiciels, bases de données, marques et brevets.{" "}
                  <strong>PANEL ANTILLES</strong> déclare n’être tenu d’aucune
                  réserve ou revendication formulée par des tiers quant à ses
                  droits sur lesdits éléments. Chacune des parties déclare
                  n’être tenue d’aucune réserve ou revendication formulée par
                  des tiers quant à ses droits sur lesdits éléments.
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE  8*/}
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <p style={{ color: "#2fa4e7" }}>Article 8 – Durée</p>
                </Accordion.Header>
                <Accordion.Body>
                  La présente charte est conclue et acceptée par le{" "}
                  <strong>PANELISTE</strong> et
                  <strong>PANEL ANTILLES</strong> pour une durée indéterminée.
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE  9*/}
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <p style={{ color: "orange" }}>Article 9 – Force majeure</p>
                </Accordion.Header>
                <Accordion.Body>
                  Sont considérés comme cas de force majeure ou cas fortuits,
                  les évènements indépendants de la volonté des parties,
                  qu’elles ne pouvaient raisonnablement être tenues de prévoir
                  et qu’elles ne pouvaient raisonnablement éviter ou surmonter,
                  dans la mesure où leur survenance rend totalement impossible
                  l’exécution des obligations.
                  <br></br>
                  <br></br>
                  Sont notamment assimilés à des cas de force majeure ou cas
                  fortuits déchargeant <strong>PANEL ANTILLES</strong> de son
                  obligation de livrer sa prestation dans les délais
                  initialement prévus : la grève de la totalité ou d’une partie
                  du personnel de <strong>PANEL ANTILLES</strong>, l’incendie,
                  l’inondation, la guerre, les épidémies, les barrières de
                  dégel, les barrages routiers, les grèves, ruptures
                  d’approvisionnement EDF-GDF, ou les ruptures
                  d’approvisionnement pour une cause non imputable à PANEL
                  ANTILLES ainsi que toute autre cause indépendante de la
                  volonté et de l’action de <strong>PANEL ANTILLES</strong>.
                  <br></br>
                  <br></br>
                  Dans de telles circonstances, <strong>
                    PANEL ANTILLES
                  </strong>{" "}
                  préviendra le{" "}
                  <strong style={{ color: "purple" }}>
                    <strong>PANELISTE</strong>
                  </strong>{" "}
                  par tous moyens de contact : mail, téléphone … aussitôt que la
                  survenance des évènements lui sera connue, les rapports liant{" "}
                  <strong>PANEL ANTILLES</strong> et le{" "}
                  <strong>PANELISTE</strong> étant alors suspendus de plein
                  droit sans indemnité, à compter de la date de survenance de
                  l’évènement.
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE  10*/}
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  <p style={{ color: "#2fa4e7" }}>
                    Article 10 – Intégralité de l’accord
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  Le présent accord conclu entre <strong>PANEL ANTILLES</strong>{" "}
                  et le <strong>PANELISTE</strong> exprime l’intégralité des
                  obligations des parties.
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE  11*/}
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <p style={{ color: "orange" }}>
                    Article 11 – Suppression du panel
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  A tout moment l’une ou l’autre des parties pourra supprimer le
                  profil du <strong>PANELISTE</strong> sur le panel{" "}
                  <strong>PANEL ANTILLES</strong>.<br></br>
                  <br></br>
                  Dans le cas où la suppression ferait suite à un manquement
                  quelconque du <strong>PANELISTE</strong> quant à ses
                  obligations mentionnées dans la présente charte, l’exécution
                  de règlements par chèque ou virement bancaire non encore
                  réalisée ou le colisage de cadeaux non encore adressé au{" "}
                  <strong>PANELISTE</strong> seront immédiatement annulés sans
                  qu’aucune indemnité ne soit due par PANEL ANTILLES et ce sans
                  préjudice de toute voie d’action en réparation du ou des
                  dommages subi(s).
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE  12*/}
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  <p style={{ color: "#2fa4e7" }}>Article 12 – Renonciation</p>
                </Accordion.Header>
                <Accordion.Body>
                  Le fait pour <strong>PANEL ANTILLES</strong> de ne pas se
                  prévaloir à un moment donné de l’une quelconque des clauses de
                  la présente, ne peut valoir renonciation à se prévaloir
                  ultérieurement de ces mêmes clauses.
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE  13*/}
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  <p style={{ color: "orange" }}>
                    Article 13 – Compétence juridictionnelle
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  La présente charte est soumise au droit français. Tous
                  différends relatifs à la validité, à l’interprétation et à
                  l’exécution de la présente charte relèveront de la compétence
                  prévue par les dispositions du Code de procédure civile.
                </Accordion.Body>
              </Accordion.Item>

              {/* ARTICLE  14*/}
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <p style={{ color: "#2fa4e7" }}>
                    Article 14 – Election de domicile
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  Pour l’exécution de la présente charte,{" "}
                  <strong>PANEL ANTILLES</strong> élit domicile en son siège
                  social, le <strong>PANELISTE</strong> à l’adresse qu’il a
                  mentionnée lors de son inscription.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          {/* FREE COLUMN */}
          <Col className="py-5 center-element"></Col>
        </Row>
      </Container>
    </main>
  );
}

export default Charte;
