// Dependencies
import { Container } from "react-bootstrap";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";

// Screens
import About from "./screens/About";
import Client from "./screens/Client";
import Paneliste from "./screens/Paneliste";
import Login from "./screens/Login";
import Register from "./screens/Register";
import Profile from "./screens/Profile";
import Charte from "./screens/Charte";
import Quotas from "./screens/Quotas";

import ResetPassword from "./screens/reset_password/ResetPassword";
import ConfirmPassword from "./screens/reset_password/ConfirmPassword";

// Enquêtes
import Framework from "./enquêtes/Framework";

function App() {
  // FACEBOOK PIXEL
  const advancedMatching = { em: "some@email.com" };
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init("1024372605039668", advancedMatching, options);

  ReactPixel.pageView();
  // ReactPixel.track(event, data);
  // ReactPixel.trackSingle("PixelId", event, data);
  // ReactPixel.trackCustom(event, data);
  // ReactPixel.trackSingleCustom("PixelId", event, data);

  // To the top when reload page (F5)
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Router>
        <Header />

        <main>
          <Container fluid="true">
            <Switch>
              <Route path="/Qui-sommes-nous" component={About} />
              <Route path="/devenir-paneliste" component={Paneliste} />
              <Route path="/realisation-enquêtes" component={Client} />
              <Route path="/connexion" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/profil" component={Profile} />
              <Route path="/quotas" component={Quotas} />

              {/* Redirection vers une/les enquête(s) en cours */}
              <Route path="/test" component={Framework} />

              {/* Charte du panéliste */}
              <Route path="/charte-paneliste" component={Charte} />

              {/* Réinitialisation du mot de passe */}
              <Route path="/mot-de-passe-oublié" component={ResetPassword} />
              <Route
                path="/réinitialisation-mot-de-passe"
                component={ConfirmPassword}
              />

              {/* Page d'accueil (à placer en dernier) */}
              <Route path="/" component={Paneliste} />
            </Switch>
          </Container>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
