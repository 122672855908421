import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

//REDUX (HOOKS + ACTIONS)
import { useSelector, useDispatch } from "react-redux";
import {
  updateSurveyParticipants,
  CagnotteUpdate,
} from "../../redux/actions/userActions";

function End({ history }) {
  // STATE DU PROFIL UTILISATEUR
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // CAGNOTTE DE L'UTILISATEUR
  const [points, setPoints] = useState(userInfo.points);

  // USE OF REDUX HOOKS (useDispatch)
  const dispatch = useDispatch();

  // FERMER LE QUESTIONNAIRE + MAJ DES POINTS + REDIRECTION PAGE PROFIL
  const updatePoints = () => {
    // mis à jour de la cagnotte
    setPoints(dispatch(CagnotteUpdate({ points: points + 80 })));

    // identification des utilisateurs ayant participé aux enquêtes
    dispatch(updateSurveyParticipants({ user: userInfo.id }));

    // redirection vers la page Profil
    history.push("/profil");
  };
  return (
    <div>
      <h3 className="text-center" style={{ color: "purple" }}>
        Merci d'avoir participer à l'enquête !
      </h3>
      <br />
      <p>
        Afin de valider votre participation, cliquer sur le bouton{" "}
        <strong style={{ color: "#82c46c" }}>Obtenir mes points</strong> en bas
        à droite. Nous vous remercions et vous disons à bientôt pour le prochain
        sondage.
      </p>
      <br />
      <p style={{ color: "#fea347", marginLeft: "50px" }}>
        <strong>Panel Antilles, Pôté Wva Péyi Nou</strong>
      </p>
      <br />
      <Button
        variant="success"
        style={{ marginLeft: "80px" }}
        onClick={updatePoints}
      >
        Obtenir mes points 😊
      </Button>
    </div>
  );
}

export default withRouter(End);
