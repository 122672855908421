// Import Dependencies
import React, { useEffect } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Accordion,
  Form,
  Button,
  Carousel,
} from "react-bootstrap";

// Images
import décideurs_img from "../images/Décideurs_bis.png";
import population_img from "../images/talking_people.png";
import img5 from "../images/data_02.png";
import img6 from "../images/data_04.png";
import analyse_sociale from "../images/Analyse_sociale.png";

// Icons
import idea_icon from "../images/Idea_icon.png";
import Statistics_icon from "../images/Statistics_icon.png";
import Decision_making from "../images/Decision_making_icon.png";
import ContactForm from "./ContactForm";

function Client() {
  useEffect(() => {
    // Back to the top when loading
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid="true" className="py-5">
      <Row>
        <Col className="d-none d-lg-block">
          {/* CAROUSSEL */}

          <Carousel className="mb-4 darker-style">
            <Carousel.Item interval={5000}>
              <img
                className="img-darker d-block w-100"
                src={img5}
                alt="Image One"
              />
              <Carousel.Caption>
                <h3 className="title-banner">Des enquêtes sur-mesure</h3>
                {/* <p>Sample Text for Image One</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <img
                className="img-darker d-block w-100"
                src={img6}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 className="title-banner">
                  Interrogez facilement vos publics
                </h3>
                {/* <p>Sample Text for Image Two</p> */}
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <br></br>

      {/* FIRST SECTION  */}
      <Container className="py-3">
        <Row>
          <h2 className="text-center py-3" style={{ color: "#FF8C00" }}>
            Notre vision : Ba Matinik An Bel Balan
          </h2>
          <p className="mb-4 text-center">
            Confrontés à la rareté des enquêtes d’opinion ou de sondages sur le
            territoire martiniquais, nous avons à coeur de mettre en place les
            moyens matériels et humains afin d’y remédier. Aussi nous entendons
            vous apporter une précieuse assistance analytique (data) dans vos
            missions du quotidien.
          </p>

          {/* ENCADRÉ N°1 */}
          <Col className="center-element py-3">
            <Card style={{ width: "22rem" }}>
              <Card.Img variant="top" src={population_img} />
              <Card.Body className="text-center">
                <Card.Title style={{ color: "	#008000" }}>
                  Donner la parole à la population
                </Card.Title>
                <Card.Text>
                  Dans toute société, il est essentiel que la population soit
                  interrogée et que l’opinion publique soit connue, afin que son
                  expression contribue aux processus décisionnels de la vie
                  sociale, politique et économique locale.
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>

          {/* ENCADRÉ N°2 */}
          <Col className="center-element py-4">
            {" "}
            <Card style={{ width: "22rem" }}>
              <Card.Img variant="top" src={décideurs_img} />
              <Card.Body className="text-center">
                <Card.Title style={{ color: "	#008000" }}>
                  Offrir une visibilité aux décideurs
                </Card.Title>
                <Card.Text>
                  Qu’il s’agisse de recueillir l’opinion de la population sur un
                  sujet de société ou d’analyser les politiques publiques mises
                  en œuvre, nos décideurs locaux ont besoin de visibilité, et
                  celle-ci passe par la mobilisation de la population.
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>

          {/* ENCADRÉ N°3 */}
          <Col className="center-element">
            {" "}
            <Card style={{ width: "22rem" }}>
              <Card.Img variant="top" src={analyse_sociale} />
              <Card.Body className="text-center">
                <Card.Title style={{ color: "	#008000" }}>
                  Étudier les dynamiques sociales
                </Card.Title>
                <Card.Text>
                  Il s'agit également d'apporter un éclairage social et sociétal
                  aux décideurs de notre île, afin de leur permettre de
                  connaître la réalité du terrain et d'apporter des solutions
                  adaptées aux différentes situations d'urgence de notre
                  territoire.
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <br></br>

      {/* SECOND SECTION  */}
      <Container fluid="true" className="blue-section">
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="py-3 text-center title-section">
            Vos avantages : Disposer de données pertinentes et d’actualité
          </h2>

          {/* FIRST CARD */}
          <Col className="center-element mb-4">
            <Card style={{ width: "20rem" }}>
              <Card.Img variant="top" src={Statistics_icon} />
              <Card.Body className="text-center">
                <Card.Title style={{ color: "#FF8C00" }}>
                  Analyser pour décider
                </Card.Title>
                <Card.Text>
                  Nous entendons vous offrir la possibilité d’agir en pleine
                  conscience, d’analyser les tendances au sein de la population
                  martiniquaise et d’évaluer vos actions directement auprès des
                  publics concernés.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* SECOND CARD */}
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Card style={{ width: "20rem" }}>
              <Card.Img variant="top" src={idea_icon} />
              <Card.Body className="text-center">
                <Card.Title style={{ color: "#FF8C00" }}>
                  Obtenir une vision claire
                </Card.Title>
                <Card.Text>
                  N’oeuvrez plus à l’aveuglette. Vous disposez désormais d’un
                  outil de mesure fiable et mobilisable en un laps de temps
                  réduit. Il est ainsi possible d’apprécier certaines dynamiques
                  de façon quantitative.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* THIRD CARD */}
          <Col className="center-element py-4">
            <Card style={{ width: "20rem" }}>
              <Card.Img variant="top" src={Decision_making} />
              <Card.Body className="text-center">
                <Card.Title style={{ color: "#FF8C00" }}>
                  Optimiser vos stratégies
                </Card.Title>
                <Card.Text>
                  Tirer profit d'une bonne connaissance du terrain et des
                  considérations de vos parties prenantes, en mettant en place
                  des stratégies pertinentes, adaptées aux besoins réels de vos
                  publics, avec un budget maîrisé.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br></br>

        {/* ACCORDION */}
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col xs={10} xl={8}>
            {/* APPROCHE QUANTI */}
            <div>
              <h2 className="text-center" style={{ color: "white" }}>
                L'approche quanti'
              </h2>
            </div>
            <Accordion defaultActiveKey="mb-4 0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  🗹 Professionnalisme de la démarche
                </Accordion.Header>
                <Accordion.Body>
                  À l’instar des instituts de sondages nationaux,{" "}
                  <strong>
                    les enquêtes seront réalisées de façon à respecter la
                    représentativité de la population martiniquaise
                  </strong>
                  , en ayant recours à la méthode des quotas sur certaines
                  variables sociodémographiques (âge, sexe, CSP, diplôme) après
                  stratification sur les communautés d’agglomération (Cap Nord,
                  CACEM, Espace Sud). La fiabilité de la démarche est garantie
                  par{" "}
                  <strong>
                    l’utilisation d’outils et de méthodes promus par l’INSEE
                  </strong>
                  . Aussi, nous avons souscrit auprès du <strong>SYNTEC</strong>
                  , principal syndicat du domaine. Du reste, les résultats et
                  les moyens ayant permis d’y parvenir sont régulièrement
                  transmis à la <strong>Commission des sondages</strong> afin
                  d’en contrôler l’intégrité, comme il est d’usage.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>📊Sondage d'opinion</Accordion.Header>
                <Accordion.Body>
                  <strong>
                    Sonder, questionner, prendre le pouls de l’opinion publique
                    sur un sujet de société
                  </strong>{" "}
                  n’aura jamais été aussi aisé. Cette possibilité s’offre à
                  tous, que vous soyez un média d’information local, une
                  institution publique ou une association.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>🏥Enquête de santé publique</Accordion.Header>
                <Accordion.Body>
                  À l’adresse des institutions et des décideurs du domaine de la
                  santé, nous avons à coeur de vous permettre d’
                  <strong>
                    interroger les habitudes de vie des Martiniquais sur
                    certains aspects (nutrition, consommation de tabac ou
                    d’alcool, fréquence d’activités physiques, sécurité
                    routière, etc.)
                  </strong>
                  . À terme, il serait même possible d’effectuer des{" "}
                  <strong>baromètres annuels</strong> pour mesurer la
                  fluctuation de certains phénomènes.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  🎯Analyse des politiques publiques
                </Accordion.Header>
                <Accordion.Body>
                  La mise en place de politiques publiques appelle mécaniquement
                  l’évaluation de ces dernières. Notre volonté de constituter un
                  panel large, entend permettre de{" "}
                  <strong>
                    mesurer la réception de ces initiatives par les publics
                    visés à l’échelle de l’île ou d’une communauté
                    d’agglomération.
                  </strong>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>📈Enquête socio-économique</Accordion.Header>
                <Accordion.Body>
                  Il devient également possible de{" "}
                  <strong>
                    réaliser des enquêtes sur les ménages et les individus quant
                    à certains aspects socio-économiques
                  </strong>
                  , tels que la consommation des ménages, les enquêtes visant la
                  clientèle, les aspirations de la population, les sujets
                  d’actualités et bien d’autres choses encore.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>📉Data visualisation</Accordion.Header>
                <Accordion.Body>
                  Chacunes de{" "}
                  <strong>
                    nos réalisations quantitatives sont livrées avec un tableau
                    de bord interactif
                  </strong>
                  , synthétisant l'ensemble des résultats de l'analyse. <br />
                  Ce format favorise une compréhension intuitive et permet au(x)
                  commanditaire(s) et aux parties prenantes d'
                  <strong>
                    obtenir des réponses visuelles et détaillées de leurs
                    interrogations
                  </strong>
                  .
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <br />
            {/* APPROCHE QUALI' */}
            <div>
              <h2 className="text-center" style={{ color: "white" }}>
                L'approche quali'
              </h2>
            </div>
            <Accordion defaultActiveKey="mb-4 0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  📑 Approfondissement de l'analyse
                </Accordion.Header>
                <Accordion.Body>
                  Les enquêtes qualitatives permettent de{" "}
                  <strong>
                    porter un regard analytique minutieux sur les problématiques
                    considérées
                  </strong>
                  . Cette approche peut s'effectuer en amont de la phase
                  quantitative afin de{" "}
                  <strong>
                    mieux apréhender une réalité sociale, comprendre comment
                    pensent et interagissent les individus,
                  </strong>{" "}
                  de façon à apporter des réponses en adéquation avec leurs
                  attentes.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>📝Entretiens semi-directifs</Accordion.Header>
                <Accordion.Body>
                  Cette méthode d'enquête se propose de questionner des
                  individus, de façon plus ou moins représentative, en fonction
                  d'un thème ou d'une problématique donnée. Elle prend la forme
                  d'un{" "}
                  <strong>
                    échange en face-à-face qui repose sur des questions
                    soigneusement élaborées dans un guide d'entretien
                  </strong>
                  . Les durées moyennes oscillent entre 30 et 60 minutes.
                  <br />
                  Afin de tirer pleinement profit de cette technique, il
                  convient de prévoir entre 10 et 15 entretiens, pour ainsi
                  bénéficier d'un faisceau d'information qui soit pertinent et
                  suffisamment diversifié.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>🔊Focus group</Accordion.Header>
                <Accordion.Body>
                  Les focus group ont une dimension confirmatoire, en ce sens
                  qu'ils cherchent à assoir des interprétations et des
                  informations recueillies en amont.{" "}
                  <strong>
                    La méthode nécessite de réunir entre 6 à 10 personnes dans
                    une salle afin de mener un échange sur des thématiques
                    préétablies.
                  </strong>{" "}
                  À l'instar des entretiens semi-directifs, un guide est
                  nécessaire afin d'orienter et de rythmer les débats.
                  <br />
                  Les résultats obtenus donnent lieu à une restitution intégrale
                  des échanges et une analyse sémantique approfondie.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  👁️Observation ethnographique
                </Accordion.Header>
                <Accordion.Body>
                  Certains faits sociaux nécessitent d'aller au-delà des
                  éléments déclaratifs des personnes interrogées, et appellent à
                  mener des observations ethnographiques dans le but de{" "}
                  <strong>
                    mieux comprendre comment se comportent les individus et
                    quelles sont leurs motivations profondes
                  </strong>
                  .
                  <br />
                  Cette méthode d'enquête nécessite une expertise et une
                  réflexivité, toutes deux essentielles à sa bonne réalisation.
                  Afin de renforcer son caractère explicatif, elle est souvent
                  jointe à la réalisation d'entretiens ou de focus group. Une
                  façon de mettre en regard les actions et les déclarations.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>☁️Nuage de mots</Accordion.Header>
                <Accordion.Body>
                  Enfin, notez que l'ensemble de{" "}
                  <strong>
                    nos prestations qualitatives sont livrées avec des
                    infographies sous forme de nuage de mots renseignant sur les
                    principales occurrences (répétitions de mots) et thématiques
                  </strong>{" "}
                  issues du discours des personnes interrogées.
                  <br />
                  Cette approche moderne{" "}
                  <strong>
                    permet aux décideurs de tirer l'essentiel des résultats en
                    un regard , pas plus
                  </strong>{" "}
                  . Ce qui favorise la lecture du rapport détaillé, également
                  fourni.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          {/* FREE COLUMN */}
        </Row>
        <br></br>
      </Container>
      <br></br>

      {/* THIRD SECTION  */}
      <Container className="py-3">
        <h2 className="mb-4 text-center" style={{ color: "#FF8C00" }}>
          Vous avez des interrogations ?
        </h2>
        <p className="mb-4 text-center">
          {" "}
          Contactez-nous en utilisant le formulaire suivant, ou en envoyant un
          mail à{" "}
          <strong style={{ color: "green" }}>
            panel.antilles972@gmail.com
          </strong>{" "}
          :<br></br>
        </p>
        <Row>
          {/* FREE COLUMN */}
          <Col></Col>
          <Col xs={12} md={8}>
            {/* Formulaire */}
            <ContactForm />
          </Col>
          {/* FREE COLUMN */}
          <Col></Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Client;
