import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  return (
    <div className=" footer-padding">
      <footer>
        <Container fluid="true">
          <Row className="center-element">
            <Col className="text-center py-3 footer-color">
              <div className="center-element">
                <Row>
                  <h5 style={{ color: "white" }}>Suivez-nous ! </h5>
                  {/* FACEBOOK */}
                  <Col style={{ color: "white" }}>
                    <a
                      style={{ color: "white" }}
                      href="https://www.facebook.com/panelantilles/"
                      target="_blank"
                    >
                      <i class="fab fa-facebook"></i>
                    </a>
                  </Col>
                  {/* INSTAGRAM */}
                  <Col style={{ color: "white" }}>
                    <a
                      style={{ color: "white" }}
                      href="https://www.instagram.com/panelantilles/"
                      target="_blank"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                  </Col>
                  {/* LINKDEDIN */}
                  <Col style={{ color: "white" }}>
                    <a
                      style={{ color: "white" }}
                      href="https://www.linkedin.com/company/panel-antilles/"
                      target="_blank"
                    >
                      <i class="fab fa-linkedin"></i>
                    </a>
                  </Col>
                  {/* TWITTER */}
                  {/* <Col style={{ color: "white" }}>
                    <i class="fab fa-twitter"></i> */}
                  {/* </Col>  */}
                </Row>
              </div>
              <br />

              <div className="copyright-style">
                Copyright © Made by{" "}
                <a href="http://www.agence-marketic.fr" target="_blank">
                  Agence Marketic
                </a>{" "}
                with ❤️
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default Footer;
