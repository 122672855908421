import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Button,
  Table,
  Alert,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { user_details, updateUserProfile } from "../redux/actions/userActions";

// Screens
import ContactForm from "./ContactForm";

function Profile({ history }) {
  // States
  const [adresse_postale, setAdresse_postale] = useState("");
  const [commune, setCommune] = useState("");
  const [agglomeration, setAgglomeration] = useState("");
  const [codePostal, setCodePostal] = useState("");

  const [csp, setCsp] = useState("");
  const [statutFamilial, setStatutFamilial] = useState("");
  const [parcoursFormation, setParcoursFormation] = useState("");
  // const [telephone, setTelephone] = useState("");

  // CAGNOTTE
  const [cagnotte, setCagnotte] = useState(0);

  // Utiliser la fonction dispatch de Redux
  const dispatch = useDispatch();

  // Paramètre d'affichage des profils utilisateurs (gérer par Redux)
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  // Paramètre de connexion (gérer par Redux)
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Message de succès pour la mise à jour du profil (gérer par Redux)
  const userUpdateProfil = useSelector((state) => state.userUpdateProfil);
  const { success } = userUpdateProfil;

  // Conditions de redirection lors de l'authentification
  useEffect(() => {
    if (!userInfo) {
      history.push("/connexion");
    } else {
      if (!user || !user.username || success || userInfo.id !== user.id) {
        dispatch(user_details("profile"));
      } else {
        setAdresse_postale(user.email);
      }
    }
  }, [dispatch, history, userInfo, user, success]);

  // Envoi des données vers le Backend pour la mise à jour du profil
  const submitHandler = (event) => {
    event.preventDefault();

    dispatch(
      updateUserProfile({
        id: user.id,
        adresse_postale: adresse_postale,
        commune: commune,
        agglomeration: agglomeration,
        adresse_postale: adresse_postale,
        code_postal: codePostal,
        csp: csp,
        statut_familial: statutFamilial,
        parcours_formation: parcoursFormation,
        // telephone: telephone,
      })
    );
  };

  return (
    <Container className="py-5 center-element">
      <Row className="py-3">
        <Col md={10}>
          <br></br>
          <br></br>
          {/* Affichage du nom de l'utilisateur */}
          {userInfo ? (
            <h3 className="py-3" style={{ color: "#FF8C00" }}>
              Bonjour {userInfo.user_name}, bienvenue sur votre profil 🙂
            </h3>
          ) : (
            <h3 className="py-3">Bienvenue sur votre profil </h3>
          )}
          <p className="text-center py-3">
            Nous sommes heureux de vous compter dans la Famille Panel Antilles.
            Nous sommes actuellement en phase de recrutement de panélistes en
            Martinique. Il nous manque des retraité(e)s, si vous en connaissez,
            n'hésitez pas à leur parler de nous.
          </p>

          <p className="text-center">
            À ce jour, nous comptons près de 1100 panélistes ! Nous remercions
            chacun(e) d'entre vous.{" "}
            <strong style={{ color: "green" }}>
              Dès lors où nous aurons atteint la barre des 1500 inscrit(e)s, les
              enquêtes pourront débuter
            </strong>
            .
          </p>
          <br />
          <p className="text-center">
            <strong>
              Nous vous demandons donc juste un peu patience. Mèsi an cha'y 😉
            </strong>
          </p>
          {/* <p className="text-center">
            Afin de nous aider à atteindre l'objectif de panélistes requis, nous
            vous invitons à partager le{" "}
            <a href="https://www.panelantilles.fr/#/register" target="_blank">
              lien d'inscription
            </a>{" "}
            à 2/3 personnes de votre entourage. Merci d'avance 👍🏽
          </p> */}
          <Tabs
            defaultActiveKey="cagnotte"
            id="uncontrolled-tab-example"
            className="py-3"
          >
            {/* FIRST TAB */}

            <Tab eventKey="enquêtes" title="⭐Enquête(s)">
              <Row className="py-3 center-element">
                <p className="py-3" style={{ color: "green" }}>
                  Les informations ci-dessus servent UNIQUEMENT
                  d'illustrations/d'exemples en attendant le début des enquêtes.
                </p>
                <Col md={9}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Type d'enquête</th>
                        <th>Points</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Sondage d'opinion</td>
                        <td>200</td>
                        <td className="center-element">
                          <Button
                            // onClick={() => {
                            //   history.push("/test");
                            // }}
                            variant="success"
                          >
                            Je participe
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Santé publique</td>
                        <td>400</td>
                        <td className="center-element">
                          <Button variant="dark">Enquête terminée</Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Politique publique</td>
                        <td>200</td>
                        <td className="center-element">
                          <Button variant="dark">Enquête terminée</Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Tab>

            {/* SECOND TAB */}
            <Tab eventKey="cagnotte" title="💰 Ma Cagnotte">
              <Row className="center-element">
                <Col md={8} className="py-3">
                  {" "}
                  <Alert variant="success">
                    {/* Affichage de la cagnotte */}
                    {userInfo ? (
                      <Alert.Heading>
                        Votre cagnotte est actuellement de :{" "}
                        <strong>{userInfo.points} points</strong>
                      </Alert.Heading>
                    ) : (
                      <p>
                        Veuillez vous reconnecter pour afficher votre cagnotte
                      </p>
                    )}

                    <p>
                      Participez aux enquêtes, cumulez des points et bénéficiez
                      de bons d’achats auprès de nos partenaires du monde de la
                      culture et du commerce. 🥳 Dès lors que vous aurez atteint
                      un des paliers de votre choix (5€, 10€, 15€, 20€), vous
                      pourrez utiliser votre cagnotte à votre guise.
                    </p>
                    <hr />
                    <p className="mb-0">
                      💡 La liste des partenaires sera divulguée prochainement.
                      Restez connecté·e !
                    </p>
                    {/* <Button
                      variant="dark"
                      onClick={() => setCagnotte(cagnotte + 1)}
                    >
                      Update Cagnotte
                    </Button> */}
                  </Alert>
                  <br />
                  <h3 style={{ color: "green" }}>Barème de points 💯</h3>
                  <br />
                  <Table striped bordered hover className="py-3">
                    <thead>
                      <tr>
                        <th>Palier</th>
                        <th>Critères</th>
                        <th>Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>#1</td>
                        <td>1 à 5 questions</td>
                        <td>200</td>
                      </tr>
                      <tr>
                        <td>#2</td>
                        <td>6 à 10 questions</td>
                        <td>400</td>
                      </tr>
                      <tr>
                        <td>#3</td>
                        <td>11 à 19 questions</td>
                        <td>600</td>
                      </tr>
                      <tr>
                        <td>#4</td>
                        <td>Plus de 20 questions</td>
                        <td>1000</td>
                      </tr>
                    </tbody>
                  </Table>
                  <br />
                  <h3 style={{ color: "green" }}>
                    Total de points et valeur en bons d'achats 🎁
                  </h3>
                  <br />
                  <Table striped bordered hover className="py-3">
                    <thead>
                      <tr>
                        <th>Total de points</th>
                        <th>Valeur du bon d'achats</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2000</td>
                        <td>5 €</td>
                      </tr>
                      <tr>
                        <td>4000</td>
                        <td>10 €</td>
                      </tr>
                      <tr>
                        <td>6500</td>
                        <td>15 €</td>
                      </tr>
                      <tr>
                        <td>8000</td>
                        <td>20 €</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Tab>

            {/* THIRD TAB */}
            <Tab eventKey="Historique" title="📑 Historique de participations">
              <Row className="center-element">
                <p className="py-3" style={{ color: "green" }}>
                  Les informations ci-dessous servent UNIQUEMENT
                  d'illustrations/d'exemples en attendant le début des enquêtes.
                </p>
                <Col md={9}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Thématique</th>
                        <th>Points</th>
                        <th>Résultats</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>02.06.2021</td>
                        <td>Santé publique</td>
                        <td>400</td>
                        <td className="center-element">
                          <Button variant="primary">Voir PDF</Button>
                        </td>
                      </tr>
                      <tr>
                        <td>12.05.2021</td>
                        <td>Politique publique</td>
                        <td>200</td>
                        <td className="center-element">
                          <Button variant="primary">Voir PDF</Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Tab>

            {/* FOURTH TAB */}

            {/* <Tab eventKey="Profil" title="❇️Modification des info' perso'">
              <Container>
                <Row className="py-3 center-element">
                  <p style={{ color: "green" }}>
                    Si votre situation a changé depuis votre inscription, nous
                    vous offrons la possibilité de modifier votre profil.
                  </p>
                  <p>
                    ⚠️ Ces informations sont{" "}
                    <strong>
                      anonymisées et nous sont très utiles pour établir les
                      quotas
                    </strong>{" "}
                    (répartition des répondants). Nous vous demandons donc
                    d'être sincère afin de ne pas biaiser les enquêtes.
                  </p>
                  <br />
                  <Col md={6} className="text-center">
                    <Form onSubmit={submitHandler}>
                      <Form.Group
                        className="py-5 text-center"
                        controlId="adresse_postale"
                      >
                        <Form.Label>Adresse postale</Form.Label>
                        <Form.Control
                          type="text"
                          value={adresse_postale}
                          placeholder=""
                          onChange={(event) =>
                            setAdresse_postale(event.target.value)
                          }
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="Commune">
                        <Form.Label>Commune</Form.Label>
                        <Form.Control
                          type="commune"
                          placeholder=""
                          value={commune}
                          onChange={(event) => setCommune(event.target.value)}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="agglomeration">
                        <Form.Label>Agglomeration</Form.Label>
                        <Form.Control
                          type="agglomeration"
                          placeholder=""
                          value={agglomeration}
                          onChange={(event) =>
                            setAgglomeration(event.target.value)
                          }
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="code-postal">
                        <Form.Label>Code postal</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={codePostal}
                          onChange={(event) =>
                            setCodePostal(event.target.value)
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="csp">
                        <Form.Label>CSP</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={csp}
                          onChange={(event) => setCsp(event.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="statut-familial">
                        <Form.Label>Statut Familial</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={statutFamilial}
                          onChange={(event) =>
                            setStatutFamilial(event.target.value)
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formation">
                        <Form.Label>Parcours Formation</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={parcoursFormation}
                          onChange={(event) =>
                            setParcoursFormation(event.target.value)
                          }
                        />
                      </Form.Group>
                     <Form.Group className="mb-3" controlId="telephone">
                        <Form.Label>Téléphone</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={telephone}
                          onChange={(event) => setTelephone(event.target.value)}
                        />
                      </Form.Group> 

                      <Button variant="dark" type="submit">
                        Mettre à jour mes informations
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Tab> */}

            {/* FIFTH TAB */}
            <Tab eventKey="contact" title="❓ Nous contacter">
              <Row className="center-element">
                <Col md={9} className="py-5">
                  <ContactForm />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

export default Profile;
