import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import confirmPassword from "../../redux/actions/userActions";
import axios from "axios";

function ConfirmPassword({ history }) {
  // email de récupération
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");

  // Utilisation du Hooks useDispatch de Redux
  const dispatch = useDispatch();

  // Fonction d'envoi de mail
  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(token);
    console.log(password);

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const data = await axios.post(
      "api/api/password_reset/confirm/",
      { token: token, password: password },
      config
    );

    // Redirection vers la page de connexion
    history.push("/connexion");
  };

  // Back to the thop when loading page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // To the top when reload page (F5)
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <Container className="login-center">
      <Row>
        <Col></Col>
        <Col xs={12} md={4}>
          <div>
            <h4 style={{ color: "#FF8C00" }} className="py-3">
              Réinitialisation du mot de passe
            </h4>
            <p style={{ color: "green" }}>
              {" "}
              ✉️ Nous vous avons envoyé par mail un code pour réinitialiser
              votre mot de passe.
            </p>
          </div>
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="token">
              <Form.Label>Code reçu par mail</Form.Label>
              <Form.Control
                required
                type="token"
                placeholder="inscrivez le code reçu par mail"
                value={token}
                onChange={(event) => setToken(event.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nouveau mot de passe</Form.Label>
              <Form.Control
                required
                type="new-password"
                placeholder="Votre nouveau mot de passe"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <Form.Text className="text-muted">
                8 caractères minimum, avec des chiffres et des lettres.
              </Form.Text>
            </Form.Group>
            <Button variant="success" type="submit">
              Valider
            </Button>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}

export default ConfirmPassword;
