// Dependencies
import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/userActions";
import { useHistory } from "react-router-dom";

// Logo
import logo from "../images/panel_antilles_logo.png";

function Header() {
  // Fonction dédiée à la connexion de l'utilisateur
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Module de déconnexion (via Redux)
  const dispatch = useDispatch();

  // Module de redirection
  const history = useHistory();

  // Fonction générant le profil + logout (utilisateurs connectés)
  const logoutHandler = () => {
    dispatch(logout());
    history.push("/connexion");
  };

  return (
    <header>
      <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <LinkContainer to="/devenir-paneliste">
            <Navbar.Brand>
              <img
                style={{ marginTop: "-15px", marginBottom: "-13px" }}
                src={logo}
              ></img>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Navbar.Collapse>
                <LinkContainer to="/Qui-sommes-nous">
                  <Nav.Link>Qui sommes-nous</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/devenir-paneliste">
                  <Nav.Link>Devenir panéliste</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/realisation-enquêtes">
                  <Nav.Link>Commander une étude</Nav.Link>
                </LinkContainer>

                {userInfo && userInfo.isAdmin && (
                  <LinkContainer to="/test">
                    <Nav.Link>test</Nav.Link>
                  </LinkContainer>
                )}
              </Navbar.Collapse>
            </Nav>
            <Nav>
              {/* Afficher la page d'enregistrement pour les non-inscrits uniquement */}
              {userInfo ? (
                <h6></h6>
              ) : (
                <LinkContainer to="/register">
                  <Nav.Link>
                    <i class="fas fa-user-plus"></i> S'inscrire
                  </Nav.Link>
                </LinkContainer>
              )}

              {/* Logique conditionnelle en cas d'utilisateur connecté */}
              {userInfo ? (
                <NavDropdown
                  title={"Nou Kontan We'w" + " " + userInfo.user_name}
                  id="username"
                >
                  <LinkContainer to="/profil">
                    <NavDropdown.Item>
                      <i class="fas fa-id-card"></i> Mon profil
                    </NavDropdown.Item>
                  </LinkContainer>

                  <NavDropdown.Item onClick={logoutHandler}>
                    <i class="fas fa-sign-out-alt"></i> Se déconnecter
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to="/connexion">
                  <Nav.Link eventKey={2}>
                    <i class="fas fa-user "> </i> Se connecter
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
