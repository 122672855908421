import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
} from "react-bootstrap";

//REDUX (HOOKS + ACTIONS)
import { useSelector, useDispatch } from "react-redux";
import {
  SurveyDisplay,
  updateSurveyParticipants,
  CagnotteUpdate,
} from "../redux/actions/userActions";

// SURVEY - ENQUETES
import Enquete from "./test/Enquete";

function Framework({ history }) {
  // STATE DU PROFIL UTILISATEUR
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // CAGNOTTE DE L'UTILISATEUR
  const [points, setPoints] = useState(userInfo.points);

  // USE OF REDUX HOOKS (useDispatch)
  const dispatch = useDispatch();

  // AFFICHAGE DES ENQUETES RÉALISÉES
  useEffect(() => {
    // Affichage des enquêtes
    dispatch(SurveyDisplay(updateSurveyParticipants({ user: userInfo.id })));

    // Redirection en cas de reconnexion
    if (!points) {
      history.push("/connexion");
    }
  }, [dispatch, points]);

  // AFFICHER LE MODAL (QUESTIONNAIRE)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // FERMER LE QUESTIONNAIRE + MAJ DES POINTS + REDIRECTION PAGE PROFIL
  const updatePoints = () => {
    setShow(false);
    // mis à jour de la cagnotte
    setPoints(dispatch(CagnotteUpdate({ points: points + 80 })));

    // identification des utilisateurs ayant participé aux enquêtes
    dispatch(updateSurveyParticipants({ user: userInfo.id }));

    // redirection vers la page Profil
    history.push("/profil");
  };

  // Redirection en cas de déconnexion
  useEffect(() => {
    if (points) {
      console.log("it's okay");
    } else {
      history.push("/connexion");
    }
  }, [userInfo, points, history]);

  // Condition : une seule participation par personne
  // Le chiffre (4) = l'ID de l'enquête dans la BDD Django / 0 est un chiffre !
  const participation = userInfo.enquetes[4].id;

  return (
    <Container className="py-5">
      <Row className="py-5">
        <Col>
          {userInfo ? (
            <h2 className="text-center py-3">
              Bienvenue sur la page d'enquête {userInfo.user_name}{" "}
            </h2>
          ) : (
            <h2 className="text-center">Bienvenue</h2>
          )}
          <h5 className="text-center" style={{ color: "green" }}>
            {" "}
            simulation de mise à jour du compteur de points
          </h5>

          <h3 className="text-center" style={{ color: "green" }}>
            {userInfo.points}
          </h3>
        </Col>
      </Row>

      {/* QUESTIONNAIRE */}

      <Row>
        <Col></Col>
        <Col>
          {/* 6 = ID de l'enquête (à faire évoluer) */}
          {participation === 6 ? (
            <Button variant="dark">Terminée</Button>
          ) : (
            <Button variant="primary" onClick={handleShow}>
              Lancer l'enquête
            </Button>
          )}

          <br />
          {/* TABLE */}
          <p className="text-center py-3">
            Test pour afficher les participations aux enquêtes
          </p>
          <Table striped bordered hover className="py-3">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Type</th>
                <th>Points gagnés</th>
              </tr>
            </thead>
            <tbody>
              {/* ID */}
              {userInfo.enquetes.map((sub) => (
                <tr>
                  <td>{sub.id}</td>
                  <td>{sub.date_participation}</td>
                  <td>{sub.thematique}</td> <td>{sub.points_attribues}</td>{" "}
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal show={show} onHide={handleClose} size="md" backdrop="static">
            <Modal.Header closeButton>
              {/* TITRE DU QUESTIONNAIRE */}
              <Modal.Title style={{ color: "green" }}>
                Questionnaire sur ...
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* ESPACE QUESTIONNAIRE */}
              <Form>
                <Row>
                  <Col>
                    <Enquete />
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="success" onClick={updatePoints}>
                Obtenir mes points
              </Button> */}
            </Modal.Footer>
          </Modal>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}

export default Framework;
