import {
  //LOGIN constants
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,

  //LOGOUT constants
  USER_LOGOUT,

  // REGISTER constants
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,

  // PANELISTES REGISTER constants
  PANELISTES_REGISTER_REQUEST,
  PANELISTES_REGISTER_SUCCESS,
  PANELISTES_REGISTER_FAIL,

  // DETAILS constants
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,

  // LIST constants
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,

  // UPDATE PROFILE constants
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,

  // DELETE constants
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,

  // UPDATE ADMIN constants
  USER_UPDATE_ADMIN_REQUEST,
  USER_UPDATE_ADMIN_SUCCESS,
  USER_UPDATE_ADMIN_FAIL,
  USER_UPDATE_ADMIN_RESET,

  // USER SURVEY DISPLAY constants
  USER_SURVEY_DISPLAY_REQUEST,
  USER_SURVEY_DISPLAY_SUCCESS,
  USER_SURVEY_DISPLAY_FAIL,

  // USER SURVEY DONE constants
  USER_SURVEY_DONE_REQUEST,
  USER_SURVEY_DONE_SUCCESS,
  USER_SURVEY_DONE_FAIL,

  // USER CAGNOTTE UPDATE constants
  USER_CAGNOTTE_UPDATE_REQUEST,
  USER_CAGNOTTE_UPDATE_SUCCESS,
  USER_CAGNOTTE_UPDATE_FAIL,

  // OINGOING SURVEY constants
  ONGOING_SURVEY_REQUEST,
  ONGOING_SURVEY_SUCCESS,
  ONGOING_SURVEY_FAIL,

  // RESET PASSWORD constants
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,

  // CONFIRM PASSWORD constants
  CONFIRM_PASSWORD_REQUEST,
  CONFIRM_PASSWORD_SUCCESS,
  CONFIRM_PASSWORD_FAIL,
} from "../constants/userConstants";

// Const LOGIN
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };

    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

// Const REGISTER
export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };

    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

// Const PANELISTES REGISTER
export const panelistesRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case PANELISTES_REGISTER_REQUEST:
      return { loading: true };

    case PANELISTES_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case PANELISTES_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

// Const DETAILS
export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };

    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };

    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case USER_DETAILS_RESET:
      return { user: {} };

    default:
      return state;
  }
};

// Const UPDATE PROFILE
export const userUpdateProfilReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };

    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_PROFILE_RESET:
      return {};

    default:
      return state;
  }
};

// Const LIST
export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };

    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };

    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };

    case USER_LIST_RESET:
      return { users: [] };

    default:
      return state;
  }
};

// Const DELETE
export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };

    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };

    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case USER_LIST_RESET:
      return { users: [] };

    default:
      return state;
  }
};

// Const UPDATE ADMIN
export const userUpdateAdminReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_ADMIN_REQUEST:
      return { loading: true };

    case USER_UPDATE_ADMIN_SUCCESS:
      return { loading: false, success: true };

    case USER_UPDATE_ADMIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_ADMIN_RESET:
      return { user: {} };

    default:
      return state;
  }
};

// Const SURVEY DISPLAY
export const userSurveyDisplayReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SURVEY_DISPLAY_REQUEST:
      return { loading: true };

    case USER_SURVEY_DISPLAY_SUCCESS:
      return { loading: false, success: true, surveyDisplay: action.payload };

    case USER_SURVEY_DISPLAY_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

// Const SURVEY PARTICIPANTS
export const userSurveyDoneReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SURVEY_DONE_REQUEST:
      return { loading: true };

    case USER_SURVEY_DONE_SUCCESS:
      return { loading: false, surveyInfo: action.payload };

    case USER_SURVEY_DONE_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

// Const CAGNOTTE UPDATE
export const userCagnotteUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CAGNOTTE_UPDATE_REQUEST:
      return { loading: true };

    case USER_CAGNOTTE_UPDATE_SUCCESS:
      return { loading: false, success: true, valeur_cagnotte: action.payload };

    case USER_CAGNOTTE_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// Const ONGOING SURVEY
export const ongoingSurveyReducer = (state = {}, action) => {
  switch (action.type) {
    case ONGOING_SURVEY_REQUEST:
      return { loading: true };

    case ONGOING_SURVEY_SUCCESS:
      return { loading: false, success: true, reponses: action.payload };

    case ONGOING_SURVEY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// Const RESET PASSWORD
export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { loading: true };

    case RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true, reponses: action.payload };

    case RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// Const CONFIRM PASSWORD
export const confirmPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_PASSWORD_REQUEST:
      return { loading: true };

    case CONFIRM_PASSWORD_SUCCESS:
      return { loading: false, success: true, reponses: action.payload };

    case CONFIRM_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
