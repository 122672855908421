// Dependencies
import React, { useEffect } from "react";
import { Container, Carousel, Row, Col, Card } from "react-bootstrap";

// Screens
import ContactForm from "./ContactForm";

// images
import data_analysis_1 from "../images/Data_analysis_1.png";
import data_analysis_2 from "../images/Data_analysis_2.png";

import Jessy_photo from "../images/Jessy_photo.png";
import Ismael_photo from "../images/Ismael_Photo.png";
import Thierry_photo from "../images/Thierry_Photo.png";

import Time_photo from "../images/Time.png";
import Law_ethics from "../images/Law_ethics.png";
import Direction_photo from "../images/Direction.png";
import Horizon_photo from "../images/Horizon.png";

function About() {
  // Back to the thop when loading page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid="true" className="py-5">
      <Row>
        <Col className="d-none d-lg-block">
          <Carousel className="darker-style">
            <Carousel.Item interval={15000}>
              <img
                className="img-darker d-block w-100"
                src={data_analysis_1}
                alt="Image One"
              />
              <Carousel.Caption>
                <h3 className="title-banner">Des spécialistes de la data...</h3>
                {/* <p>Sample Text for Image One</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={15000}>
              <img
                className="img-darker d-block w-100"
                src={data_analysis_2}
                alt="Image Two"
              />
              <Carousel.Caption>
                <h3 className="title-banner">...au service de vos objectifs</h3>
                {/* <p>Sample Text for Image Two</p> */}
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <br></br>

      {/* FIRST SECTION */}
      <Container className="py-3">
        <Row
          className="blue-section section-padding py-3"
          style={{ backgroundColor: "white" }}
        >
          <h2 className="text-center py-3" style={{ color: "#FF8C00" }}>
            Une équipe compétente et motivée
          </h2>
          <p className="text-center text-adjusted">
            Nous sommes de jeunes martiniquais désireux de contribuer, à travers
            nos savoir-faire, à la modernisation et au développement de la
            Martinique. Plus qu'un voeu pieux, nous avons décidé de joindre le
            geste à la parole, en nous investissant pleinement dans ce projet.
          </p>
          {/* FIRST COLUMN */}
          <Col className="center-element py-3">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={Ismael_photo} />
              <Card.Body>
                <Card.Title className="text-center">
                  <strong style={{ color: "#FF8C00" }}>Ismael</strong>
                </Card.Title>
                <Card.Text className="text-center">
                  <strong style={{ color: "green" }}>Le profil social</strong>
                  <br></br> Fort de plusieurs interventions dans le milieu
                  associatif, Ismaël a réalisé des enquêtes pour le compte
                  d’organismes privés. Son sens de l’organisation et son
                  approche des terrains d’étude sont une valeur sûre pour la
                  bonne coordination de l’équipe.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className="center-element py-3">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={Jessy_photo} />
              <Card.Body>
                <Card.Title className="text-center">
                  <strong style={{ color: "#FF8C00" }}>Jessy</strong>
                </Card.Title>
                <Card.Text className="text-center">
                  <strong style={{ color: "green" }}>
                    Le profil sociologique
                  </strong>{" "}
                  <br></br>{" "}
                  <p className="justify">
                    Tout au long de son parcours académique, Jessy a réalisé de
                    multiples études auprès de larges échantillons. Ses
                    compétences dans les domaines de la communication, de
                    l’infographie, et de la statistique constituent un gage de
                    qualité dans l’élaboration des comptes-rendus.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className="center-element py-3">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={Thierry_photo} />
              <Card.Body>
                <Card.Title className="text-center">
                  <strong style={{ color: "#FF8C00" }}>Thierry</strong>
                </Card.Title>
                <Card.Text className="text-center">
                  <strong style={{ color: "green" }}>
                    Le profil technique
                  </strong>
                  <br></br> Thierry a accumulé une solide expérience en
                  ingénierie informatique, en développant son savoir-faire dans
                  la conception et l’administration de sites web. À cela
                  s’ajoute ses compétences en matière de conception d'enquête et
                  de sondage d'opinion.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* SECOND SECTION : NOS VALEURS */}
        <Container className="blue-section" fluid="true">
          <Row className="py-3 section-padding">
            <h2 className="text-center py-3 title-section">Nos valeurs</h2>
            <p className="text-center title-section">
              Outre nos compétences, nous sommes particulièrement sensibles à
              notre éthique de travail et au fait de délivrer à nos partenaires
              une véritable valeur ajoutée.
            </p>

            {/* FIRST CARD */}
            <Col md={3} className="center-element ">
              <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={Horizon_photo} />
                <Card.Body>
                  <Card.Title
                    className="text-center"
                    style={{ color: "#FF8C00" }}
                  >
                    Vous permettre d'élargir vos horizons
                  </Card.Title>
                  <Card.Text className="text-center">
                    Présentez-nous les problématiques auxquelles vous faites
                    face et nous vous apporterons notre assistance dans
                    l'élaboration des enquêtes, ainsi que les solutions adaptées
                    à vos prises de décision stratégique.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* SECOND CARD */}
            <Col md={3} className="center-element py-3">
              <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={Time_photo} />
                <Card.Body>
                  <Card.Title
                    className="text-center"
                    style={{ color: "#FF8C00" }}
                  >
                    Veiller au respect des délais définis
                  </Card.Title>
                  <Card.Text className="text-center">
                    Le respect est une notion non négociable, et le terreau de
                    relations durables. Aussi, nous mettons un point d’honneur à
                    réaliser les missions qui nous sont confiées dans les délais
                    impartis et les objectifs définis en amont.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* THIRD CARD */}
            <Col md={3} className="center-element py-3">
              <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={Direction_photo} />
                <Card.Body>
                  <Card.Title
                    className="text-center"
                    style={{ color: "#FF8C00" }}
                  >
                    Porter une aide à la décision
                  </Card.Title>
                  <Card.Text className="text-center">
                    L’ensemble de nos prestations vise à vous apporter une
                    réelle plus-value ; qu’il s’agisse d'une meilleure
                    connaissance de vos publics (opinions, besoins,
                    satisfaction) afin de vous permettre d’initier les actions
                    adéquates.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* FOURTH CARD */}
            <Col md={3} className="center-element py-3">
              <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={Law_ethics} />
                <Card.Body>
                  <Card.Title
                    className="text-center"
                    style={{ color: "#FF8C00" }}
                  >
                    {" "}
                    Garantir une éthique de travail
                  </Card.Title>
                  <Card.Text className="text-center">
                    Nous vous garantissons une éthique de travail dans la
                    collecte et le traitement des données, en conformité avec
                    les recommandations de la{" "}
                    <strong style={{ color: "green" }}> CNIL </strong> et du{" "}
                    Règlement Général sur la Protection des Données{" "}
                    <strong style={{ color: "green" }}>(RGPD)</strong>.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* THIRD SECTION */}
        <Row
          className="section-padding py-3 "
          style={{ backgroundColor: "white" }}
        >
          <h2 className="py-3 text-center" style={{ color: "#FF8C00" }}>
            Nous contacter
          </h2>
          <p className="text-center">
            {" "}
            Contactez-nous en utilisant le formulaire suivant, ou en envoyant un
            mail à{" "}
            <strong style={{ color: "green" }}>
              contact@agence-marketic.fr
            </strong>{" "}
            :
          </p>
          {/* FREE COLUMN */}
          <Col md={2}></Col>
          <Col className="py-3 center-element" md={8}>
            <ContactForm />
          </Col>
          {/* FREE COLUMN */}
          <Col md={2}></Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
