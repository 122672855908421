import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import login from "../redux/actions/userActions";
import { Link } from "react-router-dom";

// Components
import Message from "../components/Message";
import Loader from "../components/Loader";

function Login({ location, history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Fonction de redirection (towards register page)
  const redirect = location.search ? location.search.split("=")[1] : "/";

  // Utiliser la fonction dispatch de Redux
  const dispatch = useDispatch();

  // Envoi des données vers le Backend pour authentification
  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(login(email, password));
  };

  // Paramètre de connexion (gérer par Redux)
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  // Empêcher les reconnexions aux utilisateurs identifiés
  useEffect(() => {
    if (userInfo) {
      history.push("/profil");
    }

    // Back to the top when loading
    window.scrollTo(0, 0);
  }, [history, userInfo]);

  return (
    <Container className="py-3 login-center">
      <Row>
        <h2 className="mb-4 text-center" style={{ color: "#FF8C00" }}>
          Connexion à votre compte
        </h2>
        {/* FREE COLUMN */}
        <Col></Col>

        <Col xs={12} md={4}>
          {/* ACTIONS : Loading ou message d'erreur */}
          {error && (
            <Message variant="danger">E-mail ou mot de passe incorrect</Message>
          )}
          {loading && <Loader />}

          {/* FORMULAIRE */}
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="votre email"
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(event) => setPassword(event.target.value)}
              />
            </Form.Group>

            <Button variant="success" type="submit">
              Se connecter
            </Button>
            <div className="py-3">
              <a href="https://www.panelantilles.fr/#/mot-de-passe-oublié">
                Mot de passe oublié
              </a>
            </div>
          </Form>
          <Row className="py-3">
            <Col>
              🟠 Pas encore adhérent(e) ? Inscrivez-vous{" "}
              <Link
                to={redirect ? "/register?redirect=${redirect}" : "/register"}
              >
                ici
              </Link>
            </Col>
          </Row>
        </Col>
        {/* FREE COLUMN */}
        <Col></Col>
      </Row>
    </Container>
  );
}

export default Login;
