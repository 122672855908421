import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import emailjs from "emailjs-com";

// Components
import Message from "../components/Message";

function ContactForm() {
  // Fonction d'envoi de mail

  function sendEmail(event) {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_uw2ij8d",
        "template_7s68li6",
        event.target,
        "user_Hf89sn6dTR0AswzT9LpQ2"
      )
      .then((resp) => console.log(resp))
      .catch((err) => console.log(err));

    event.target.reset();

    <Message variant="success">Message envoyé avec succès ! </Message>;
  }
  return (
    <Container>
      <Form onSubmit={sendEmail}>
        <Row>
          <Col xs={6}>
            <Form.Group className="mb-3" controlId="prenom">
              <Form.Label>Prénom</Form.Label>
              <Form.Control placeholder="Prénom" name="prenom" />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-3" controlId="nom">
              <Form.Label>Nom</Form.Label>
              <Form.Control placeholder="Nom" name="nom" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Votre email</Form.Label>
              <Form.Control placeholder="email valide" name="email" />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Form.Group className="mb-3" controlId="objet">
            <Form.Label>Objet du message</Form.Label>
            <Form.Control placeholder="objet du message" name="objet" />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group className="mb-3" controlId="message">
            <Form.Label>Votre message</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Exprimez-vous"
              name="message"
              style={{ height: "120px" }}
            />
          </Form.Group>
        </Row>
        <br></br>
        <Button variant="success" type="submit">
          Envoyer
        </Button>
      </Form>
    </Container>
  );
}

export default ContactForm;
