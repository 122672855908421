import React from "react";
import { Button } from "react-bootstrap";

function Start({ onQuizStart }) {
  return (
    <div>
      <div>
        <div className="content">
          <h4 style={{ color: "purple" }}>Enquête sur ...</h4>
          <p>
            Bonjour,
            <br />
            L'enquête qui va suivre porte sur ... Nous rappelons que celle-ci
            est anonyme, aussi nous vous invitons à jouer franc-jeu et à
            répondre le plus sincèrement que possible. Votre voix compte, alors
            exprimez-la librement 😉
          </p>
          <Button variant="success" onClick={onQuizStart}>
            C'est parti !
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Start;
