import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { paneliste } from "../redux/actions/userActions";

// Components
import Message from "../components/Message";
import Loader from "../components/Loader";

// Image
import family from "../images/family_message_v2.jpg";

function Register({ location, history }) {
  // VARIABLES QUESTIONNAIRE PANÉLISTES
  const [civilite, setCivilite] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [user_name, setUser_name] = useState("");

  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [annee_naissance, setAnnee_naissance] = useState("");
  const [mois_naissance, setMois_naissance] = useState("");
  const [adresse_postale, setAdresse_postale] = useState("");
  const [complement_adresse, setComplement_adresse] = useState("");
  const [commune, setCommune] = useState("");
  const [agglomeration, setAgglomeration] = useState("");

  const [code_postal, setCode_postal] = useState("");
  const [csp, setCsp] = useState("");
  const [statut_familial, setStatut_familial] = useState("");
  const [parcours_formation, setParcours_formation] = useState("");
  const [telephone, setTelephone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  // Utiliser la fonction dispatch de Redux
  const dispatch = useDispatch();

  // Fonction de redirection (towards register page)
  const redirect = location.search ? location.search.split("=")[1] : "/";

  // Paramètre de connexion (gérer par Redux)
  const panelisteRegister = useSelector((state) => state.panelisteRegister);
  const { error, loading, userInfo } = panelisteRegister;

  // Conditions de redirection lors de l'authentification
  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
    // Back to the top when loading page
    window.scrollTo(0, 0);
  }, [history, userInfo, redirect]);

  // Envoi des données vers le Backend pour authentification
  const submitHandler = (event) => {
    event.preventDefault();

    if (password !== confirmPassword || email !== confirmEmail) {
      setMessage(
        "Vérifiez que votre EMAIL et/ou votre MOT DE PASSE sont identiques dans les deux cases"
      );
    } else {
      dispatch(
        paneliste(
          civilite,
          prenom,
          nom,
          user_name,
          email,
          annee_naissance,
          mois_naissance,
          adresse_postale,
          complement_adresse,
          commune,
          agglomeration,
          code_postal,
          csp,
          statut_familial,
          parcours_formation,
          telephone,
          password
        )
      );
    }
  };

  return (
    <div>
      <Container fluid="true">
        <Row>
          <Col className="d-block d-sm-none bottom-padding">
            {" "}
            <img src={family} className="w-100"></img>{" "}
          </Col>
        </Row>
      </Container>
      <Container className="py-5 sign-in-center">
        <Row xs={10} xl={8}>
          <h2 className="mb-4 text-center" style={{ color: "#FF8C00" }}>
            Formulaire d'inscription
          </h2>
          {/* <p className="text-center">
            ⭐ Rapide, gratuit et sans engagement ⭐
          </p> */}
          <p className="text-center">
            ⭐{" "}
            <strong>
              Participez à des enquêtes rémunérées et gagnez des bons d'achat
              auprès de nos partenaires locaux{" "}
            </strong>
            !⭐
          </p>
          <p className="text-center" style={{ color: "purple" }}>
            Plus de 1000 inscriptions à ce jour !
          </p>
          <p className="text-center">
            <strong style={{ color: "green" }}>
              {" "}
              Les enquêtes sont 100% anonymes.{" "}
            </strong>
            Les questions suivantes ont pour <strong>SEUL BUT</strong> de nous
            aider à établir un échantillon représentatif de la population
            martiniquaise, à l'image de ce que réalisent les instituts de
            sondage nationaux (IPSOS, IFOP, etc.). Inscrivez-vous et aidez-nous
            à faire avancer la Martinique !
          </p>

          <p className="text-center" style={{ color: "brown" }}>
            Le formulaire s'adresse uniquement aux personnes résidant
            actuellement en Martinique.
          </p>
          <br />

          {/* FREE COLUMN */}
          <Col></Col>
          <Col xs={12} md={8}>
            {/* Formulaire */}
            <Form onSubmit={submitHandler}>
              <Row>
                {" "}
                <Form.Group as={Col} controlId="civilite">
                  <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                    Civilité
                  </Form.Label>
                  <Form.Select
                    required
                    defaultValue="Sélectionnez"
                    type="civilite"
                    value={civilite}
                    onChange={(event) => setCivilite(event.target.value)}
                  >
                    <option hidden value="">
                      Sélectionnez
                    </option>
                    <option>Monsieur</option>
                    <option>Madame</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <br></br>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="prenom">
                    <Form.Label
                      style={{ color: "#228B22", fontWeight: "bold" }}
                    >
                      Prénom
                    </Form.Label>
                    <Form.Control
                      required
                      type="prenom"
                      placeholder="prénom"
                      value={prenom}
                      onChange={(event) => setPrenom(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="Nom">
                    <Form.Label
                      style={{ color: "#228B22", fontWeight: "bold" }}
                    >
                      Nom
                    </Form.Label>
                    <Form.Control
                      required
                      type="Nom"
                      placeholder="Nom"
                      value={nom}
                      onChange={(event) => setNom(event.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  {" "}
                  <Form.Group className="mb-3" controlId="username">
                    <Form.Label
                      style={{ color: "#228B22", fontWeight: "bold" }}
                    >
                      Pseudo utilisé sur la plateforme
                    </Form.Label>
                    <Form.Control
                      required
                      type="username"
                      placeholder="Ex : Titi972"
                      value={user_name}
                      onChange={(event) => setUser_name(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="age">
                    <Form.Label
                      style={{ color: "#228B22", fontWeight: "bold" }}
                    >
                      Année de naissance
                    </Form.Label>
                    <Form.Select
                      required
                      type="age"
                      // placeholder="ex : 1986"
                      value={annee_naissance}
                      onChange={(event) =>
                        setAnnee_naissance(event.target.value)
                      }
                    >
                      <option hidden value="">
                        Sélectionnez
                      </option>
                      <option>2003</option>
                      <option>2002</option>
                      <option>2001</option>
                      <option>2000</option>

                      <option>1999</option>
                      <option>1998</option>
                      <option>1997</option>
                      <option>1996</option>
                      <option>1995</option>
                      <option>1994</option>
                      <option>1993</option>
                      <option>1992</option>
                      <option>1991</option>
                      <option>1990</option>

                      <option>1989</option>
                      <option>1988</option>
                      <option>1987</option>
                      <option>1986</option>
                      <option>1985</option>
                      <option>1984</option>
                      <option>1983</option>
                      <option>1982</option>
                      <option>1981</option>
                      <option>1980</option>

                      <option>1979</option>
                      <option>1978</option>
                      <option>1977</option>
                      <option>1976</option>
                      <option>1975</option>
                      <option>1974</option>
                      <option>1973</option>
                      <option>1972</option>
                      <option>1971</option>
                      <option>1970</option>

                      <option>1969</option>
                      <option>1968</option>
                      <option>1967</option>
                      <option>1966</option>
                      <option>1965</option>
                      <option>1964</option>
                      <option>1963</option>
                      <option>1962</option>
                      <option>1961</option>
                      <option>1960</option>

                      <option>1959</option>
                      <option>1958</option>
                      <option>1957</option>
                      <option>1956</option>
                      <option>1955</option>
                      <option>1954</option>
                      <option>1953</option>
                      <option>1952</option>
                      <option>1951</option>
                      <option>1950</option>

                      <option>1949</option>
                      <option>1948</option>
                      <option>1947</option>
                      <option>1946</option>
                      <option>1945</option>
                      <option>1944</option>
                      <option>1943</option>
                      <option>1942</option>
                      <option>1941</option>
                      <option>1940</option>

                      <option>1939</option>
                      <option>1938</option>
                      <option>1937</option>
                      <option>1936</option>
                      <option>1935</option>
                      <option>1934</option>
                      <option>1933</option>
                      <option>1932</option>
                      <option>1931</option>
                      <option>1930</option>

                      <option>1929</option>
                      <option>1928</option>
                      <option>1927</option>
                      <option>1926</option>
                      <option>1925</option>
                      <option>1924</option>
                      <option>1923</option>
                      <option>1922</option>
                      <option>1921</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="mois">
                    <Form.Label
                      style={{ color: "#228B22", fontWeight: "bold" }}
                    >
                      Mois de naissance
                    </Form.Label>

                    <Form.Select
                      required
                      defaultValue="..."
                      type="mois"
                      value={mois_naissance}
                      onChange={(event) =>
                        setMois_naissance(event.target.value)
                      }
                    >
                      <option hidden value="">
                        Sélectionnez
                      </option>
                      <option>Janvier</option>
                      <option>Février</option>
                      <option>Mars</option>
                      <option>Avril</option>
                      <option>Mai</option>
                      <option>Juin</option>
                      <option>Juillet</option>
                      <option>Août</option>
                      <option>Septembre</option>
                      <option>Octobre</option>
                      <option>Novembre</option>
                      <option>Décembre</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3" controlId="adresse">
                <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                  Adresse postale
                </Form.Label>
                <Form.Control
                  required
                  type="adresse"
                  placeholder=""
                  value={adresse_postale}
                  onChange={(event) => setAdresse_postale(event.target.value)}
                />
              </Form.Group>

              {/* <Form.Group className="mb-3" controlId="complement-adresse">
                <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                  Complément d'adresse
                </Form.Label>
                <Form.Control
                  type="adresse"
                  placeholder=""
                  value={complement_adresse}
                  onChange={(event) =>
                    setComplement_adresse(event.target.value)
                  }
                />
              </Form.Group> */}

              <Row className="mb-3">
                <Form.Group as={Col} controlId="commune">
                  <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                    Commune de résidence
                  </Form.Label>
                  <Form.Select
                    required
                    defaultValue="Sélectionnez..."
                    type="commune"
                    value={commune}
                    onChange={(event) => setCommune(event.target.value)}
                  >
                    <option hidden value="">
                      Sélectionnez
                    </option>
                    <option>L'Ajoupa-Bouillon</option>
                    <option>Basse-Pointe</option>
                    <option>Bellefontaine</option>
                    <option>Case-Pilote</option>
                    <option>Ducos</option>
                    <option>Fonds-Saint-Denis</option>
                    <option>Fort-de-France</option>
                    <option>Grand'Rivière</option>
                    <option>Gros-Morne</option>
                    <option>La Trinité</option>
                    <option>Le Carbet</option>
                    <option>Le Diamant</option>
                    <option>Le François</option>
                    <option>Le Lorrain</option>
                    <option>Le Lamentin</option>

                    <option>Le Marigot</option>
                    <option>Le Marin</option>
                    <option>Le Morne-Rouge</option>
                    <option>Le Morne-Vert</option>
                    <option>Le Prêcheur</option>
                    <option>Le Robert</option>
                    <option>Le Vauclin</option>
                    <option>Les Anses-d'Arlet</option>
                    <option>Les Trois-Îlets</option>
                    <option>Macouba</option>
                    <option>Rivière-Pilote</option>
                    <option>Rivière-Salée</option>
                    <option>Sainte-Anne</option>
                    <option>Saint-Esprit</option>
                    <option>Saint-Joseph</option>
                    <option>Sainte-Luce</option>
                    <option>Sainte-Marie</option>
                    <option>Saint-Pierre</option>
                    <option>Schœlcher</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="agglomeration">
                  <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                    Agglomération
                  </Form.Label>
                  <Form.Select
                    required
                    defaultValue="Sélectionnez..."
                    type="agglomeration"
                    value={agglomeration}
                    onChange={(event) => setAgglomeration(event.target.value)}
                  >
                    <option hidden value="">
                      Sélectionnez
                    </option>
                    <option>Nord</option>
                    <option>Centre</option>
                    <option>Sud</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  {" "}
                  <Form.Group as={Col} controlId="code_postal">
                    <Form.Label
                      style={{ color: "#228B22", fontWeight: "bold" }}
                    >
                      Code postal
                    </Form.Label>
                    <Form.Select
                      required
                      defaultValue="Sélectionnez..."
                      type="code_postal"
                      value={code_postal}
                      onChange={(event) => setCode_postal(event.target.value)}
                    >
                      <option hidden value="">
                        Sélectionnez
                      </option>
                      <option>97200</option>
                      <option>97201</option>
                      <option>97202</option>
                      <option>97203</option>
                      <option>97204</option>
                      <option>97205</option>
                      <option>97206</option>
                      <option>97207</option>
                      <option>97208</option>
                      <option>97209</option>
                      <option>97210</option>
                      <option>97211</option>
                      <option>97212</option>
                      <option>97213</option>
                      <option>97214</option>
                      <option>97215</option>
                      <option>97216</option>
                      <option>97217</option>
                      <option>97218</option>
                      <option>97219</option>
                      <option>97220</option>
                      <option>97221</option>
                      <option>97222</option>
                      <option>97223</option>
                      <option>97224</option>
                      <option>97225</option>
                      <option>97226</option>
                      <option>97227</option>
                      <option>97228</option>
                      <option>97229</option>
                      <option>97230</option>
                      <option>97231</option>
                      <option>97232</option>
                      <option>97233</option>
                      <option>97234</option>
                      <option>97240</option>
                      <option>97250</option>
                      <option>97260</option>
                      <option>97270</option>
                      <option>97280</option>
                      <option>97290</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <br></br>
              <Row>
                <Form.Group as={Col} controlId="CSP">
                  <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                    CSP
                  </Form.Label>
                  <Form.Select
                    required
                    defaultValue="Sélectionnez..."
                    type="CSP"
                    value={csp}
                    onChange={(event) => setCsp(event.target.value)}
                  >
                    <option hidden value="">
                      Sélectionnez
                    </option>
                    <option>Agriculteurs exploitants</option>
                    <option>Artisans, commerçants et chefs d'entreprise</option>
                    <option>
                      Cadres et professions intellectuelles supérieures
                    </option>
                    <option>Professions intermédiaires</option>
                    <option>Employés</option>
                    <option>Ouvriers</option>
                    <option>Retraités</option>
                    <option>Sans activités</option>
                    <option>Étudiants</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="statut_familial">
                  <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                    Statut familial
                  </Form.Label>
                  <Form.Select
                    required
                    defaultValue="Sélectionnez..."
                    type="statut_familial"
                    value={statut_familial}
                    onChange={(event) => setStatut_familial(event.target.value)}
                  >
                    <option hidden value="">
                      Sélectionnez
                    </option>
                    <option>Célibataire</option>
                    <option>En couple ou Union libre</option>
                    <option>Divorcé(e)</option>
                    <option>Marié(e)</option>
                    <option>Pacsé(e)</option>
                    <option>Veuve / Veuf</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <br></br>
              <Row>
                <Form.Group as={Col} controlId="parcours_formation">
                  <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                    Parcours de formation
                  </Form.Label>
                  <Form.Select
                    required
                    defaultValue="Sélectionnez..."
                    type="parcours_formation"
                    value={parcours_formation}
                    onChange={(event) =>
                      setParcours_formation(event.target.value)
                    }
                  >
                    <option hidden value="">
                      Sélectionnez
                    </option>
                    <option>
                      Aucun diplôme ou certificat d'études primaires
                    </option>
                    <option>Brevet des collèges, DNB</option>
                    <option>CAP, BEP ou équivalent</option>
                    <option>
                      Baccalauréat, brevet professionnel ou équivalent
                    </option>
                    <option>Bac +2 / Bac +3</option>
                    <option>Bac +5 et au delà</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="telephone">
                  <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                    Numéro de téléphone
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="0696 ..."
                    value={telephone}
                    onChange={(event) => setTelephone(event.target.value)}
                  />
                  <Form.Text className="text-muted">
                    * Certaines enquêtes seront transmises sur What's App
                  </Form.Text>
                </Form.Group>
              </Row>
              <br></br>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="e-mail">
                  <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                    Insérez un e-mail valide
                  </Form.Label>
                  <Form.Control
                    required
                    type="e-mail"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <Form.Text className="text-muted">
                    * Les enquêtes seront principalement transmises par mail
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} controlId="e-mail confirm">
                  <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                    Confirmez votre e-mail
                  </Form.Label>
                  <Form.Control
                    required
                    type="emailConfirm"
                    placeholder="Confirmez votre e-mail"
                    value={confirmEmail}
                    onChange={(event) => setConfirmEmail(event.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="pw">
                  <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                    Choisissez un mot de passe
                  </Form.Label>
                  <Form.Control
                    required
                    type=""
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="pw">
                  <Form.Label style={{ color: "#228B22", fontWeight: "bold" }}>
                    Confirmez votre mot de passe
                  </Form.Label>
                  <Form.Control
                    required
                    type=""
                    placeholder=""
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-3 py-3" id="formGridCheckbox">
                <Form.Check
                  required
                  type="checkbox"
                  label="⬅️ Cochez la case pour accepter la charte du panéliste"
                />
                <Form.Label>
                  <a href="#/charte-paneliste" target="_blank">
                    Voir la charte
                  </a>{" "}
                </Form.Label>
              </Form.Group>

              {/* ACTIONS : Loading ou message d'erreur */}
              {error && (
                <Message variant="warning">
                  Cet email a déjà été utilisé pour créer un compte, veuillez le
                  changer
                </Message>
              )}
              {message && <Message variant="danger">{message}</Message>}
              {loading && <Loader />}

              <Button variant="success" type="submit">
                Devenir panéliste
              </Button>
            </Form>
          </Col>
          {/* FREE COLUMN */}
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Register;
