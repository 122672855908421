import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer,
  userRegisterReducer,
  panelistesRegisterReducer,
  userDetailsReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateProfilReducer,
  userUpdateAdminReducer,
  userSurveyDisplayReducer,
  userSurveyDoneReducer,
  userCagnotteUpdateReducer,
  ongoingSurveyReducer,
  resetPasswordReducer,
  confirmPasswordReducer,
} from "./redux/reducers/userReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  panelisteRegister: panelistesRegisterReducer,
  userDetails: userDetailsReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdateProfil: userUpdateProfilReducer,
  userUpdateAdmin: userUpdateAdminReducer,
  userSurveyDisplay: userSurveyDisplayReducer,
  userSurveyDone: userSurveyDoneReducer,
  userCagnotteUpdate: userCagnotteUpdateReducer,
  ongoingSurvey: ongoingSurveyReducer,
  resetPassword: resetPasswordReducer,
  confirmPassword: confirmPasswordReducer,
});

// GESTION DU STOCKAGE CONNEXIONS UTILISATEURS
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

// VALEURS DU LOCAL|STORAGE
const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

// VALIDATION DU STOCKAGE FINAL
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
