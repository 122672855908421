import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import passwordReset from "../../redux/actions/userActions";
import axios from "axios";

function ResetPassword({ history }) {
  // email de récupération
  const [email, setEmail] = useState("");

  // Fonction d'envoi de mail
  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(email);

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const data = await axios.post(
      "api/api/password_reset/",
      { email: email },
      config
    );

    // Redirection vers la page du choix du nouveau mot de passe
    history.push("/réinitialisation-mot-de-passe");
  };

  // Back to the thop when loading page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // To the top when reload page (F5)
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  return (
    <Container className="login-center">
      <Row>
        <Col></Col>
        <Col xs={12} md={4}>
          <div>
            <h4 style={{ color: "#FF8C00" }} className="py-3">
              Mot de passe oublié
            </h4>
            <p style={{ color: "green" }}>
              {" "}
              Veuillez insérer votre email afin de recevoir les instructions
              pour changer de mot de passe.
            </p>
          </div>
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3">
              {/* <Form.Label>votre email</Form.Label> */}
              <Form.Control
                // required
                type="email"
                placeholder="Votre email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Button variant="success" type="submit">
              Envoyer
            </Button>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}

export default ResetPassword;
