import axios from "axios";
import {
  //LOGIN constants
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,

  //LOGOUT constants
  USER_LOGOUT,

  // REGISTER constants
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,

  // PANELISTES REGISTER constants
  PANELISTES_REGISTER_REQUEST,
  PANELISTES_REGISTER_SUCCESS,
  PANELISTES_REGISTER_FAIL,

  // DETAILS constants
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,

  // UPDATE PROFILE constants
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,

  // LIST constants
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,

  // DELETE constants
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,

  // UPDATE ADMIN constants
  USER_UPDATE_ADMIN_REQUEST,
  USER_UPDATE_ADMIN_SUCCESS,
  USER_UPDATE_ADMIN_FAIL,

  // SURVEY DISPLAY constants
  USER_SURVEY_DISPLAY_REQUEST,
  USER_SURVEY_DISPLAY_SUCCESS,
  USER_SURVEY_DISPLAY_FAIL,

  // SURVEY DONE constants
  USER_SURVEY_DONE_REQUEST,
  USER_SURVEY_DONE_SUCCESS,
  USER_SURVEY_DONE_FAIL,

  // USER CAGNOTTE UPDATE constants
  USER_CAGNOTTE_UPDATE_REQUEST,
  USER_CAGNOTTE_UPDATE_SUCCESS,
  USER_CAGNOTTE_UPDATE_FAIL,

  // ONGOING SURVEY constants
  ONGOING_SURVEY_REQUEST,
  ONGOING_SURVEY_SUCCESS,
  ONGOING_SURVEY_FAIL,

  // RESET PASSWORD
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,

  // CONFIRM PASSWORD
  CONFIRM_PASSWORD_REQUEST,
  CONFIRM_PASSWORD_SUCCESS,
  CONFIRM_PASSWORD_FAIL,
} from "../constants/userConstants";

// Fonction LOGIN
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      "api/users/login",
      { email: email, password: password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.alerte
          ? error.response.data.alerte
          : error.message,
    });
  }
};

export default login;

// Fonction LOGOUT
export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_LIST_RESET });
  dispatch({ type: USER_DETAILS_RESET });
};

// Fonction REGISTER
export const register = (username, email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      "api/users/register",
      { username: username, email: email, password: password },
      config
    );

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.alerte
          ? error.response.data.alerte
          : error.message,
    });
  }
};

// Fonction PANELISTES REGISTER
export const paneliste =
  (
    civilite,
    prenom,
    nom,
    user_name,
    email,
    annee_naissance,
    mois_naissance,
    adresse_postale,
    complement_adresse,
    commune,
    agglomeration,
    code_postal,
    csp,
    statut_familial,
    parcours_formation,
    telephone,
    password
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PANELISTES_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        "api/users/register",
        {
          civilite: civilite,
          prenom: prenom,
          nom: nom,
          user_name: user_name,
          email: email,
          annee_naissance: annee_naissance,
          mois_naissance: mois_naissance,
          adresse_postale: adresse_postale,
          complement_adresse: complement_adresse,
          commune: commune,
          agglomeration: agglomeration,
          code_postal: code_postal,
          csp: csp,
          statut_familial: statut_familial,
          parcours_formation: parcours_formation,
          telephone: telephone,
          password: password,
        },
        config
      );

      dispatch({
        type: PANELISTES_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: PANELISTES_REGISTER_FAIL,
        payload:
          error.response && error.response.data.alerte
            ? error.response.data.alerte
            : error.message,
      });
    }
  };

// Fonction DETAILS
export const user_details = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`api/users/${id}/`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.alerte
          ? error.response.data.alerte
          : error.message,
    });
  }
};

// Fonction UPDATE PROFILE
export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put("api/users/profile/update/", user, config);

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.alerte
          ? error.response.data.alerte
          : error.message,
    });
  }
};

// Fonction LIST
export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`api/users/members`, config);

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.alerte
          ? error.response.data.alerte
          : error.message,
    });
  }
};

// Fonction DELETE
export const deleteUsers = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`api/users/delete/${id}`, config);

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.alerte
          ? error.response.data.alerte
          : error.message,
    });
  }
};

// Fonction UPDATE ADMIN
export const UsersUpdateAdmin = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_ADMIN_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `api/admin/update/${user.id}/`,
      user,
      config
    );

    dispatch({
      type: USER_UPDATE_ADMIN_SUCCESS,
      // payload: data,
    });

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_ADMIN_FAIL,
      payload:
        error.response && error.response.data.alerte
          ? error.response.data.alerte
          : error.message,
    });
  }
};

// Fonction SURVEY DISPLAY
export const SurveyDisplay = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_SURVEY_DISPLAY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`api/survey`, config);

    dispatch({
      type: USER_SURVEY_DISPLAY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_SURVEY_DISPLAY_FAIL,
      payload:
        error.response && error.response.data.alerte
          ? error.response.data.alerte
          : error.message,
    });
  }
};

// Fonction AJOUT PARTICIPANTS AUX SONDAGES
export const updateSurveyParticipants =
  (user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_SURVEY_DONE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      // Information sur l'enquête (BDD DJANGO)
      const {
        userSurveyDone: { surveyInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // ###  NUMÉRO DE L'ENQUETE  ###
      const surveyNumber = 5;

      const { data } = await axios.put(
        `api/survey_record/${surveyNumber}`,
        user,
        config
      );

      dispatch({
        type: USER_SURVEY_DONE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_SURVEY_DONE_FAIL,
        payload:
          error.response && error.response.data.alerte
            ? error.response.data.alerte
            : error.message,
      });
    }
  };

// Fonction UPDATE CAGNOTTE
export const CagnotteUpdate = (points) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_CAGNOTTE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `api/cagnotte_update/${userInfo.id}`,
      points,
      config
    );

    dispatch({
      type: USER_CAGNOTTE_UPDATE_SUCCESS,
      payload: userInfo.points,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_CAGNOTTE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.alerte
          ? error.response.data.alerte
          : error.message,
    });
  }
};

// Fonction ENQUETE EN COURS
export const OngoingSurvey =
  (
    sexe,
    age,
    agglomeration,
    csp,
    question_a,
    question_b,
    question_c,
    question_d
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ONGOING_SURVEY_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // ###  NUMÉRO DE L'ENQUETE  ###
      const surveyNumber = 5;

      const { data } = await axios.post(
        `api/enquete/${surveyNumber}/${userInfo.id}`,
        {
          sexe: sexe,
          age: age,
          agglomeration: agglomeration,
          csp: csp,
          question_a: question_a,
          question_b: question_b,
          question_c: question_c,
          question_d: question_d,
        },
        config
      );

      dispatch({
        type: ONGOING_SURVEY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ONGOING_SURVEY_FAIL,
        payload:
          error.response && error.response.data.alerte
            ? error.response.data.alerte
            : error.message,
      });
    }
  };

// RESET PASSWORD
export const passwordReset = (email) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      "http://127.0.0.1:8000/api/api/password_reset/",
      {
        email: email,
      },
      config
    );

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.alerte
          ? error.response.data.alerte
          : error.message,
    });
  }
};

// CONFIRM PASSWORD
export const confirmPassword = (token, password) => async (dispatch) => {
  try {
    dispatch({ type: CONFIRM_PASSWORD_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      "api/api/password_reset/confirm/",

      {
        token: token,
        password: password,
      },
      config
    );

    dispatch({
      type: CONFIRM_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONFIRM_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.alerte
          ? error.response.data.alerte
          : error.message,
    });
  }
};
